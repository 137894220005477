import { MessageCircleQuestion, Lightbulb } from 'lucide-react'
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import {OpenAI, AzureOpenAI} from 'openai'
import axios from "axios";
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../../services/supabase'
import getUserLocale from 'get-user-locale'
import { getStreak } from 'datetime-streak'
import { PostDisplayType, PostType } from '@/services/post-service/types'



type MainPostListProps = {
  posts: PostDisplayType[] | undefined
}

const MAX_RETRIES = 0;
const RETRY_DELAY = 15000; // 1 second

async function retryableAxiosPost(url: string, data: any, config: any, retries = 0): Promise<any> {
  try {
    return await axios.post(url, data, config);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
      console.log("429 에러 발생, 재시도 중...")
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryableAxiosPost(url, data, config, retries + 1);
    }
    throw error;
  }
}



type ExamplesProps = {
  onClick: (value: string) => void
}


const ThingsToThink = () => {


const EXAMPLE = `
{
  "result": [
    "my friends",
    "my familes",
    "my dream"
  ]
}
`


  const navigate = useNavigate()
  const { userId, bio, name } = useUserStore()

  const [thoughtsJSON, setThoughtsJSON] = useState<string>('Fetching thoughts...');

  const [thoughtsSucceed, setThoughtsSucceed] = useState(false);
  
  //const [question, setQuestion] = useState<string>('Generating question...');
  //const [secondQuestion, setSecondQuestion] = useState<string>('Generating Question...');
  //const [thirdQuestion, setThirdQuestion] = useState<string>('Generating Question...');


  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  const [streak, setStreak] = useState(0);
  const [postLength, setPostlength] = useState(0);

  console.log("현재 유저의 아이디는:")
  console.log(userId)
  console.log("현재 유저 바이오: "+bio)
  console.log("현재 유저 이름: "+name)

  let handleQuestionClick = (value: string) => {
    console.log("this is question: ")
    console.log(value)
    navigate('/write', { state: { question: value } })
  }

  var titleElement = <></>



  useEffect(() => {

    const createQuestion = async () => {

      var filterLocale; 
      filterLocale = getUserLocale();

      if(filterLocale == 'ko-KR'){
        filterLocale = 'kr'
      } else {
        filterLocale = 'en'
      }
      //한국 아니면 다 영어로 표기하기 

      console.log(filterLocale)


      //이 사람이 지금까지 쓴 포스트 다 불러오기 
      const { data: postHistory, error: postHistoryError } = await supabase
      .from('post')
      .select('content')
      .eq('user_id', userId);


      //이 사람 personal question 보기 
      const { data: thoughtsHistory, error: thoughtsHistoryError } = await supabase
      .from('user')
      .select('recent_thoughts, recent_thoughts_date')
      .eq('user_id', userId);

      if(!thoughtsHistoryError){

        const date = new Date();

        //var dateChanged = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
        var dateChanged = date.getFullYear()+'-'+((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))+'-'+((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
        console.log(dateChanged)

        //console.log(questionHistory[0].personal_question_date)
        //console.log(dateChanged)
        //console.log(questionHistory[0].personal_question_date != dateChanged)


        if(thoughtsHistory[0].recent_thoughts == null || thoughtsHistory[0].recent_thoughts_date != dateChanged){ 
          
          console.log("여기를 들어와 버렸다..")//요즘 생각들 새로 생성해야할 때 

          if(!postHistoryError){

            //console.log(data)

            if(postHistory.length>=4){
    
    
              //console.log("지금까지 유저가 쓴 포스트: ")
              //console.log(data)
    
              var postList = ''
              //const postList =  "'" + JSON.stringify(data) + "'"
              //console.log(postList)
    
              for(var i=0; i<postHistory.length; i++){
                //console.log(postHistory[i].content)
                postList += (i.toString() +': '+postHistory[i].content + ' //// ')
              }
    
              //console.log(postList)

              var SystemPrompt = `you will be given a list of journals that a user wrote within the past few days. Each post is separated by '////.' Carefully read the journals and 
              make a list of topics and keywords that the user has been thinking a lot recently. The list should be in the language that the user mostly uses.

              return your list in a JSON object format, and the list should be labeled as 'result.'
              There should be minimum 3, maximum 5 items in the list. 
              Do not put json in front of the response, and  at the end of the response.
              `
              
              if(thoughtsHistory[0].recent_thoughts != null) {
                SystemPrompt = SystemPrompt + "additionally, this is the previous list user received. The new list you generate should not be the same with this one: "+thoughtsHistory[0].recent_thoughts
              }
    
              //openai 호출 
              try {
                const completion2 = await axios.post(
                  'https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-melonn01/chat/completions?api-version=2024-09-01-preview',
                  {
                    model: 'gpt-4o-mini',
                    messages: [
                      { role: 'system', content: SystemPrompt},
                      { role: 'user', content: postList },
                    ],
                    temperature: 0.8,
                  },
                  {
                    headers: {
                      'api-key': 'e9607dca07eb4b4ebdde6d2cd11a5163',
                    }
                  }
                );
                // If the request is successful, the response will contain data
                //console.log("현재 모델:");
                //console.log(model);
                console.log("요즘 생각하는 것들 목록:");
                console.log(completion2.data.choices[0].message.content); // Access `data.choices`


                
            
                setThoughtsJSON(completion2.data.choices[0].message.content)

                //supabase 로 유저 데이터에 넣어주기 

                const tableName = 'user';
                const primaryKeyName = 'user_id';
                const body = {
                  user_id: userId, // The primary key value for the user row to be updated
                  recent_thoughts: completion2.data.choices[0].message.content,
                  recent_thoughts_date: dateChanged // Date format should match your database's requirements
                };

                await updateData(tableName, primaryKeyName, body);
            
              
              } catch (error) {
    
                //openai로 질문 생성 안되면 그냥 원래 랜덤 질문 넣어주기 
                console.error("Error occurred during OpenAI request:", error);
    
                const date = new Date().getDate();
                console.log("today's date: "+date)
                //const randomIndex = 
                const questionSentence = `
                {
                  "result": [
                    "There was an error... Please try again."
                  ]
                }`
                console.log(questionSentence)
                setThoughtsJSON(questionSentence)
              }
    
            }
    
            else { //포스트가 0개일때 
              const date = new Date().getDate();
              console.log("today's date: "+date)
              //const randomIndex = 
              const questionSentence = `
                {
                  "result": [
                    "You should write more than 3 posts to see this!"
                  ]
                }`
              console.log(questionSentence)
              setThoughtsJSON(questionSentence)
            }
            
          }
        }

        else if(thoughtsHistory[0].recent_thoughts != null && thoughtsHistory[0].recent_thoughts_date == dateChanged){

          //이미 생성된 오늘의 질문이 있다면 그냥 그대로 넣어준다. 새로 생성하려고 api 부를 필요 없이. 
          //console.log("이미 알고 있는 게 있으니까!")
          setThoughtsJSON(thoughtsHistory[0].recent_thoughts)
        }
        
      }

      else { //에러가 와서 포스트를 불러오지 못했을 때 
        const date = new Date().getDate();
        console.log("today's date: "+date)
        //const randomIndex = 
        const questionSentence = `
                {
                  "result": [
                    "There was an error... Please try again."
                  ]
                }`
        console.log(questionSentence)
        setThoughtsJSON(questionSentence) 
      }


      

      
      
      console.log(error)
      console.log(titleElement)


      /*
      try {
        const completion2 = await retryableAxiosPost(
          'https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-melonn01/chat/completions?api-version=2024-09-01-preview',
          {
            model: model,
            messages: [
              { role: 'system', content: SystemPrompt },
              ...conversations(),
              { role: 'user', content: inputPrompt },
            ],
            temperature: 0.8,
          },
          {
            headers: {
              'api-key': 'e9607dca07eb4b4ebdde6d2cd11a5163',
            }
          }
        );
        // If the request is successful, the response will contain data
        //console.log("현재 모델:");
        //console.log(model);
        console.log("Azure OpenAI generation!");
        console.log(completion2.data.choices[0].message.content); // Access `data.choices`
    
        return completion2.data.choices[0].message.content
    
      
      } catch (error) {
        console.error("Error occurred during OpenAI request:", error);
      }
      */

      /*
      try {
        // Simulate a data fetch
        const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        const result = await response.json();
        setData(result); // Update the state with fetched data
      } catch (error) {
        setError(error); // Handle any error that occurs
      } finally {
        setIsLoading(false); // Stop the loading indicator
      }
      */
    };

    createQuestion(); // Call the function to fetch data
  }, []); // The empty dependency array means this runs once when the component mounts


  
  


  



  return (
    

    <div className='w-[100%] flex justify-center items-center mt-3'>
    <div className='grid grid-flow-row gap-1 mt-4 p-4 rounded-[12px] bg-gray-100/50 w-[90%] sm:w-[100%]'>
    <p>Your recent thoughts... 🤔 </p>

    {thoughtsJSON === 'Fetching thoughts...' ? (
  <div
    className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
    onClick={() => {
      
    }}
  >
    <div className='text-[10px] flex justify-center items-center box w-5 h-5 rounded-[4px] mr-2 bg-gray-100'>
      <Lightbulb className='w-[14px]' />
    </div>
    {thoughtsJSON}
  </div>
) : (
  JSON.parse(thoughtsJSON).result.map((thought: any, i: any) => (
    <div
      key={i}
      className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
      onClick={() => {
        
      }}
    >
      <div className='text-[10px] flex justify-center items-center box w-5 h-5 rounded-[4px] mr-2 bg-gray-100'>
        <Lightbulb className='w-[14px]' />
      </div>
      {thought}
    </div>
  ))
)}

         
    </div>
  </div>
  )
}

export default React.memo(ThingsToThink)
