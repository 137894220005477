import { MessageCircleQuestion } from 'lucide-react'
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import {OpenAI, AzureOpenAI} from 'openai'
import axios from "axios";
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../../services/supabase'
import getUserLocale from 'get-user-locale'
import { last } from 'lodash'
import { getStreak } from 'datetime-streak'



const MAX_RETRIES = 0;
const RETRY_DELAY = 15000; // 1 second

async function retryableAxiosPost(url: string, data: any, config: any, retries = 0): Promise<any> {
  try {
    return await axios.post(url, data, config);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
      console.log("429 에러 발생, 재시도 중...")
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryableAxiosPost(url, data, config, retries + 1);
    }
    throw error;
  }
}



type ExamplesProps = {
  onClick: (value: string) => void
}


const StreakBox = () => {

  const navigate = useNavigate()
  const { userId, bio, name } = useUserStore()

  const [question, setQuestion] = useState<string>('Generating question...');
  const [secondQuestion, setSecondQuestion] = useState<string>('Generating Question...');
  const [thirdQuestion, setThirdQuestion] = useState<string>('Generating Question...');


  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const [streak, setStreak] = useState(0);


  console.log("현재 유저의 아이디는:")
  console.log(userId)
  console.log("현재 유저 바이오: "+bio)
  console.log("현재 유저 이름: "+name)

  let handleQuestionClick = (value: string) => {
    console.log("this is question: ")
    console.log(value)
    navigate('/write', { state: { question: value } })
  }

  var titleElement = <></>



  useEffect(() => {

    const createQuestion = async () => {

      
      //글쓴 날짜들 구하기 
      const {data: streakData, error: streakError} = await supabase 
      .from('post')
      .select('created_at')
      .eq('user_id', userId)
      .is('status', null)

      var streakCount = 0; 


      if(streakData!=null){

        
        
        //var tmpDay = 0; 
        //var today = new Date().getDate();
        //var lastWroteDay = streakData[streakData.length-1].created_at.split("T")[0].split("-")[2];

        //console.log("오늘 날짜: " + today)
        //console.log("마지막으로 쓴 날: " + lastWroteDay)
        
        var today = new Date(); 

        var dates = [];

        dates.push(today)
        console.log("오늘 : ")
        console.log(today)


      
        
        for(var i=streakData.length-1; i>=0; i--){


          //console.log(data[i].created_at);
          dates.push(streakData[i].created_at);

          //var today = new Date().getDate();
          //console.log(today)
          
        }

        console.log("dates :")
        console.log(dates)

        var streak = getStreak(dates);
        console.log("streak: ")
        console.log(streak)


        //yyyy-mm-dd 포맷 스트링 구해주기 
        const date = new Date();

        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;

        console.log(streak.activityDates[formattedDate])

      
        if(streak.currentDailyStreak > 0) {

          if(streak.activityDates[formattedDate] > 1) { //오늘 이미 글 쓴 거가 있으면 
            setStreak(streak.currentDailyStreak)
          } else {
            setStreak(streak.currentDailyStreak-1) //오늘 글 쓴 게 없으면 
          }
        } else {
          setStreak(0)
        }

        //setStreak(streak.currentDailyStreak)

        //setSecondQuestion(data[0].content)
        //setThirdQuestion(data[1].content)
      }


    };

    createQuestion(); // Call the function to fetch data
  }, []); // The empty dependency array means this runs once when the component mounts


  
  


  



  return (

    //made a small change
    //pleace get updated...
    <div className='w-[15%] flex justify-center items-center mt-6'>
    <div className='flex flex-col justify-center items-center gap-1 mt-4 p-2 rounded-[8px] border-2 border-gray-200 sm:w-[96%]'>
      {titleElement}
      <div
        className='flex items-center justify-center mt-1 cursor-pointer text-gray-500 hover:text-gray-700'
        onClick={() => {}}
      >
        <p className="text-base font-semibold text-center">🔥 {streak}</p>
      </div>
    </div>
    </div>
  )
  
}

export default React.memo(StreakBox)
