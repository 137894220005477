import { PERSONAS } from '../utils/prompts'
import {OpenAI, AzureOpenAI} from 'openai'
import { DefaultAzureCredential, getBearerTokenProvider, InteractiveBrowserCredential } from "@azure/identity";
import axios from "axios";
import axiosRetry from 'axios-retry';
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../services/supabase'
import { useUserStore } from '../store/useUserStore'




/*
const credential = new DefaultAzureCredential();
const scope = "https://cognitiveservices.azure.com/.default";
const azureADTokenProvider = getBearerTokenProvider(credential, scope);


const deployment = "gpt-4o-melonn01";
const apiVersion = "2024-09-01-preview";
const options = { azureADTokenProvider, deployment, apiVersion }
const client = new AzureOpenAI(options);
*/


export const openai = new OpenAI({
  apiKey: process.env.REACT_APP_OPENAI_KEY,
  dangerouslyAllowBrowser: true,
})


const MAX_RETRIES = 5;
const RETRY_DELAY = 15000; // 1 second

async function retryableAxiosPost(url: string, data: any, config: any, retries = 0): Promise<any> {
  try {
    return await axios.post(url, data, config);
  } catch (error) {

    //그냥 axios로 한번 더 시도 
    try {
      console.log("trying with different deployment...")
      return await axios.post('https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-mini-melonn/chat/completions?api-version=2024-08-01-preview' ,data, config);

    } catch(error) {
      console.log("tried different deployment...but still error..."); 
    }

    if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
      console.log("429 에러 발생, 재시도 중...")
       
      //다른 deployment로 시도


      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryableAxiosPost(url, data, config, retries + 1);
    }
    throw error;
  }
}





export const chatComplete = async (
  personaId: string,
  content: string, //content는 유저가 새로 단 내용이다. 
  convs?: any[],
  url?: string,
  bio?: string,
  emotion?: string | null,
  title?: string | null,
  userId?: string | null,
) => {
  const conversations = () => {
    if (convs) return convs
    else return []
  }


  //console.log("conversations함수의결과:")
  //console.log(conversations())

  
  //시스템 프롬프트 가져오기 
  var SystemPrompt = PERSONAS.find((doc) => doc.id == personaId)?.system

  
  //감정과 타이틀 가져오기 
  console.log("받은 감정: "+emotion)

  if(emotion != null){
    SystemPrompt = SystemPrompt + "also, I'm feeling "+emotion+" at the moment. Please consider my feeling when you talk to me."
  }

  if(title != null){
    SystemPrompt = SystemPrompt + "also, this is the title of my journal: "+title+" . Please consider how this overarching theme would have been related to my journal content as well."
  }


  //지금까지 썼던 포스트 불러오기 
  const { data, error } = await supabase
      .from('post')
      .select('content')
      .eq('user_id', userId)

  
  if(!error) {


    var postList = ''
          //const postList =  "'" + JSON.stringify(data) + "'"
          //console.log(postList)

    for(var i=0; i<data.length; i++){
      console.log(data[i].content)
      postList += (i.toString() +': '+data[i].content + ' //// ')
    }

    //console.log(postList)

    SystemPrompt = SystemPrompt + "finally, you will be provided with the list of previous journals written. Each journal is separated by '////'. When generating comment, carefully review previous journals and try to consider personal context and situation of the user. If you can find connection between the current journal and previous journal, please mention or bring that connection up. The previous journals: "+postList


  }

  


  let prompt = ''
  //이 prompt는 유저 프롬프트임. 
  if (convs && convs.length > 0) {
    prompt = content //만약에 이전에 한 대화가 있으면 프롬프트는 그냥 유저가 이번에 새로 쓴 말이다. 
  } else { //만약에 이전에 한 대화가 없다면 (이런 경우가 있을 수 있나..?)
    if (bio)
      prompt =
        `
          This is additional information. But do not refer to my bio unless if it is necessary to answer my post..
          My bio : ${bio}
        ` +
        PERSONAS.find((doc) => doc.id == personaId)?.user +
        'new post I wrote : ' +
        content
    else
      prompt =
        PERSONAS.find((doc) => doc.id == personaId)?.user +
        'new post I wrote : ' +
        content
  }

  const inputPrompt: any[] = [{ type: 'text', text: prompt }]
  if (url) {
    inputPrompt.push({
      type: 'image_url',
      image_url: {
        url: url,
      },
    })
  }

  const model = process.env.NODE_ENV == 'development' ? 'gpt-4o-mini' : 'gpt-4o-mini'

  //console.log("agent function 제대로 되는지 체크")
  //console.log(SystemPrompt)
  //console.log(conversations())
  //console.log(inputPrompt)

  /*
  const completion = await openai.chat.completions.create({
    messages: [
      {
        role: 'system',
        content: SystemPrompt,
      },
      ...conversations(),
      {
        role: 'user',
        content: inputPrompt,
      },
    ],
    temperature: 0.8,
    model: model,
  })
  */


  
  //console.log("전달되는 프롬프트 체크: ")
  //console.log(SystemPrompt)
  //console.log(conversations())
  //console.log(inputPrompt)
  

  try {
    const completion2 = await retryableAxiosPost(
      'https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-melonn01/chat/completions?api-version=2024-09-01-preview',
      {
        model: model,
        messages: [
          { role: 'system', content: SystemPrompt },
          ...conversations(),
          { role: 'user', content: inputPrompt },
        ],
        temperature: 0.8,
      },
      {
        headers: {
          'api-key': 'e9607dca07eb4b4ebdde6d2cd11a5163',
        }
      }
    );
    // If the request is successful, the response will contain data
    //console.log("현재 모델:");
    //console.log(model);
    console.log("Azure OpenAI generation!");
    console.log(completion2.data.choices[0].message.content); // Access `data.choices`

    return completion2.data.choices[0].message.content

  
  } catch (error) {
    console.error("Error occurred during OpenAI request:", error);
  }

  
}

