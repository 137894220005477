import React, { useState } from 'react'
import withAnimation from '../components/templates/withAnimation'
import PageTopBar from '../components/morecules/PageTopBar'
import { Bell, CircleHelp, Lock, ReceiptText, Text, MessagesSquare, Bot} from 'lucide-react'
import { useSession } from '../hooks/auth'
import CustomAlert from '../components/organisms/CustomAlert'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../store/useUserStore'
import { sb, supabase } from '../services/supabase'
import { UserService } from '../services/user-service'
import AgentSettingModal  from '../components/morecules/AgentSettingModal'

const SettingPage = () => {
  const [logoutOpen, setLogoutOpen] = useState<boolean>(false)
  const [signoutOpen, setSignoutOpen] = useState<boolean>(false)
  const { signOut } = useSession()
  const navigate = useNavigate()
  const { userId, name } = useUserStore()
  const [isOpen, setIsOpen] = useState(false);

  const SettingOptions = [
    {
      icon: <Text className='w-5' />,
      text: 'About',
      onClick: () => {
        window.open(
          'https://spicy-pufferfish-699.notion.site/About-e39c781cedb04ae7b1c4e0ac523d8ab5?pvs=74'
        )
      },
    },
    {
      icon: <Lock className='w-5' />,
      text: 'Privacy and policy',
      onClick: () => {
        navigate('/policy')
      },
    },
    // {
    //   icon: <ReceiptText className='w-5' />,
    //   text: 'Terms and conditions',
    //   onClick: () => {
    //     navigate('/terms')
    //   },
    // },
    {
      icon: <CircleHelp className='w-5' />,
      text: 'Frequently Asked Questions',
      onClick: () => {
        navigate('/faq')
      },
    },
    
    {
      icon:  <Bot className='w-5' />,
      text: 'Agent Settings',
      onClick: () => {
        setIsOpen(true)
      },
    },
    
    {
      icon: <MessagesSquare className='w-5' />,
      text: 'Join Discord',
      onClick: () => {
        window.open(
          'https://discord.gg/BEQRz4vqPj'
        )
      },
    },
  ]

  const deleteAccount = async () => {
    const body = {
      user_id: userId,
      name: name,
      content: `ACCOUNT DELETE REQUEST : ${userId}`,
    }
    const { data, error } = await supabase.from('feedback').insert(body)

    const res = await UserService.UpdateUser('userId', {
      userId: userId,
      type: 3,
    })

    signOut()
  }

  return (
    <div className='relative'>
      <PageTopBar text='Settings' />
      {SettingOptions.map((item) => {
        return (
          <div
            key={item.text}
            className='p-3 flex flex-row cursor-pointer hover:bg-slate-50 rounded-[4px]'
            onClick={() => item.onClick()}
          >
            <div className='px-1'>{item.icon}</div>
            <div className='pl-2'>{item.text}</div>
          </div>
        )
      })}
      <div
        onClick={() => setLogoutOpen(true)}
        className='border-t-[1px] mt-4 border-t-gray-200 text-[#ff0000e7] p-4 font-medium cursor-pointer'
      >
        Log out
      </div>
      <div
        onClick={() => setSignoutOpen(true)}
        className='border-t-gray-200 text-[#ff0000e7] p-4 font-medium cursor-pointer'
      >
        Delete account
      </div>
      <CustomAlert
        open={logoutOpen}
        setOpen={setLogoutOpen}
        title='Logout'
        desc='Are you sure you want to logout?'
        onClick={() => signOut()}
      />
      <CustomAlert
        open={signoutOpen}
        setOpen={setSignoutOpen}
        title='Delete Account'
        desc='Are you sure you want to delete account? Once you delete the account, you won’t be able to restore it, including all posts and comments. It takes about 24 hours to delete the account.'
        onClick={() => deleteAccount()}
      />
       {isOpen && (
        <AgentSettingModal isOpen={isOpen} setIsOpen={setIsOpen}> </AgentSettingModal>
      )}
     

    </div>
    
  )
}

export default withAnimation(SettingPage)
