import { CommentDisplayType } from '@/services/comment-service/types'
import ContentDisplay from './ContentDisplay'
import React from 'react'
import ReactPlayer from 'react-player'

type CommentDisplayProps = {
  comment: CommentDisplayType
  isLine: boolean
}

const CommentDisplay = ({ comment, isLine }: CommentDisplayProps) => {

  let reactPlayerCode: React.ReactNode;

  if(comment.content.includes("YouTube Link:")){ //보여줘야 할 유튜브 영상이 있다면
    let words = comment.content.split(" ");
    let youtubeLink = words[words.length - 1];
    console.log("youtubeLink:")
    console.log(youtubeLink);

    reactPlayerCode = <ReactPlayer url={youtubeLink} />
  }

  return (
    <div>
      <ContentDisplay content={comment} isLine={isLine} types='comment' />
      
      {comment.comments?.map((reply) => {
        return (
          <div key={reply.commentId}>
            <CommentDisplay
              comment={reply}
              isLine={
                reply.comments && reply.comments.length > 0 ? true : false
              }
            />
          </div>
        )
      })}
    </div>
  )
}

export default React.memo(CommentDisplay)
