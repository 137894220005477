import React, { useEffect, useRef, useState } from 'react'
import { useUserStore } from '../store/useUserStore'
import TextareaAutosize from 'react-textarea-autosize'
import { formatDate, timeAgo } from '../utils/formatDate'
import { PostService } from '../services/post-service'
import { v4 } from 'uuid'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Controller, useForm } from 'react-hook-form'
import { chatComplete, openai } from '../services/agent'
import {
  CommentDisplayType,
  CommentQueryType,
} from '../services/comment-service/types'
import { CommentService } from '../services/comment-service'
import ParentContent from '../components/morecules/ParentContent'
import { useReplyStore } from '../store/useReplyStore'
import { Camera, Check, Images, X } from 'lucide-react'
import { PERSONAS } from '../utils/prompts'
import Snackbar from '../components/morecules/SnackBar'
import { SnackBarTypes } from '../components/morecules/SnackBarUI'
import { usePostStore } from '../store/usePostStore'
import { useQueryClient } from 'react-query'
import { mainPostQueryKey } from '../hooks/posts'
import withAnimation from '../components/templates/withAnimation'
import { BlockService } from '../services/block-service'
import { FunnelService } from '../services/log-service'
import { insertUnderComment } from '../utils/write'
import Examples from './WritePage/Examples'
import Emotions from './WritePage/Emotions'
import { supabase } from '../services/supabase'
import {
  checkNationality,
  getRandomSeconds,
  postProcessComment,
} from './WritePage/utils'
import sha256 from 'crypto-js/sha256';
import { encryptData, decryptData } from '../utils/crypto';
import { PostQueryType } from '@/services/post-service/types'


interface CreatePostDto {
  content: string
  title: string
}

let key: CryptoKey | null = null;
let encryptedKey: string | null = null;

const WritePage = () => {


  const { userId, name, imgUrl, email, bio, privacyMode, singleMode } = useUserStore()
  const { parentId = '' } = useParams()
  const navigate = useNavigate()
  const {
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { isSubmitting},
  } = useForm<CreatePostDto>()
  const { parent } = useReplyStore()
  const { content, setContent } = usePostStore()
  const [uploadedImage, setUploadedImage] = useState<string>('')
  const [imgFile, setImgFile] = useState<any>(null)
  const [selectedEmotion, setSelectedEmotion] = useState<string | null>(null);
  const [submissionType, setSubmissionType] = useState<'withAI' | 'withoutAI'>('withoutAI');


  const location = useLocation()
  const todayQuestion = location.state?.question 

  // console.log("오늘의 질문은? : "+todayQuestion)

  const queryClient = useQueryClient()

  const postComment = async (dto: CreatePostDto) => {

    //dto로 받는것은 그냥 입력한 댓글의 내용이다. 

   
    //작성 화면에서 'post' 버튼을 눌렀을 때 postComment가 실행이 된다 
   

    //parent는 내가 답을 하고 있는 대상의 정보를 담고 있고, content는 원본 게시물의 내용이다. 


    if (parent && content) {
      const recommentId = v4()
      const commentBody: CommentDisplayType = {
        commentId: recommentId,
        postId: parent.postId,
        content: dto.content,
        userId: userId,  
        parentCommentId: parent.typed == 'comment' ? null : parentId,
        createdBy: 'user',
        createdAt: new Date().toISOString(),
        likesCount: 0,
        ownerId: userId,
      }
      const res = await CommentService.InsertComment(commentBody)
      
      let newContent = { ...content }

      

      if (!res){ //댓글이 정상적으로 슈파베이스에 올라갔을 때

        //유저가 쓴 포스트일때 
        if(content.createdBy == 'user' || content.createdBy == null){

          if(parent.typed == 'comment'){ //그냥 유저가 자기 게시물에 새 댓글을 달 때 

            //console.log("당신이 쓴 포스트에 당신이 댓글을....")

            //newContent 생성하기 
            newContent = {
              ...content,
              comments: content.comments
                ? [
                    ...content.comments,
                    {
                      ...commentBody,
                      user: content.user
                    }
                  ]
                : [commentBody],
            }
            queryClient.setQueryData(parent.postId, newContent)
            setContent(newContent)
            return

          } else if(parent.typed == 'reply'){ //유저가 자기 댓글이든 ai 댓글이든 답글을 달 때 

            if(parent.userId == commentBody.userId){ //유저가 자기 댓글에 답글을 달 때 

              //console.log("당신이 쓴 포스트에 당신이 쓴 댓글에 당신이 답글을...")
              const totalComments = await insertUnderComment(
                content,
                commentBody,
                content.user,
                parentId
              )
              newContent = {
                ...content,
                comments: totalComments,
              }
  
              queryClient.setQueryData(parent.postId, newContent)
              setContent(newContent)
              return 

            } else {

              //console.log("당신이 쓴 포스트에 ai가 쓴 댓글에 당신이 답글을...")
              const totalComments = await insertUnderComment(
                content,
                commentBody,
                content.user,
                parentId
              )
              newContent = {
                ...content,
                comments: totalComments,
              }
  
              queryClient.setQueryData(parent.postId, newContent)
              setContent(newContent)
            }

          }

        }

        //ai가 쓴 포스트일 때 
        else if(content.createdBy == 'ai'){

          let tempUserInfo = {
            "name" : name, 
            "profileImageUrl" : imgUrl, 
            "email" : email, 
            "userId" : userId
          }

          if(parent.typed == 'comment'){ //유저가 ai 게시물에 새 댓글을 달 때 

            //console.log("ai가 쓴 포스트에 당신이 댓글을...")

            //newContent 생성하고 설정하기 
            //commentBody까지는 문제가 없음. 그 이후에 newContent합칠때 문제가 있어보임. 
            //user에 content.user를 넣어버려서 ai 데이터가 들어가게 됨. user에다가 인간 유저 데이터를 넣어야 한다. 
            //일단 이 문제는 조금 지켜보는 걸로... 
            //commentBody에는 유저에 대한 자세한 정보가 올라가지 않는다. 그냥 userId만 들어가는데 userId는 똑바로 들어감. 

           

            newContent = {
              ...content,
              comments: content.comments
                ? [
                    ...content.comments,
                    {
                      ...commentBody,
                      user: tempUserInfo,
                    },
                  ]
                : [commentBody],
            }
            queryClient.setQueryData(parent.postId, newContent)
            setContent(newContent)

          } else if(parent.typed == 'reply'){ //답글 달 때. 이때는 무조건 ai와의 대화를 이어나가는 케이스밖에 존재하지 않음. 
            //console.log("ai가 쓴 포스트에 당신이 단 댓글에 달린 ai 답글에 당신이 답글을 달 때....")

            const totalComments = await insertUnderComment(
              content,
              commentBody,
              tempUserInfo,
              parentId
            )
            newContent = {
              ...content,
              comments: totalComments,
            }

            queryClient.setQueryData(parent.postId, newContent)
            setContent(newContent)
          }

        }

      }




      /*
      if (!res) { //내가 쓴 게시물에 댓글을 달 때 
        if ((parent.typed == 'comment' && (content.createdBy == 'user' || content.createdBy == null)) || (parent.typed == 'reply' && parent.userId == commentBody.userId)) { 
          newContent = {
            ...content,
            comments: content.comments
              ? [
                  ...content.comments,
                  {
                    ...commentBody,
                    user: content.user,
                  },
                ]
              : [commentBody],
          }
         
          queryClient.setQueryData(parent.postId, newContent)
          setContent(newContent)
          console.log("newContent1:")
          console.log(newContent)
          return
        } else if (parent.typed == 'reply') { //ai 댓글에 답글을 달았을 때 
          const totalComments = await insertUnderComment(
            content,
            commentBody,
            content.user,
            parentId
          )
          newContent = {
            ...content,
            comments: totalComments,
          }
          
          console.log("newContent2:") //ai 댓글에 답글을 달았을 때는 이 경우로 오게 된다. 
          console.log(newContent)

          queryClient.setQueryData(parent.postId, newContent)
          setContent(newContent)
        } 
      }
      */

      //그러니까 답글을 달고 나서 ai 답변 생성 행하는 것이다. 
      //여기에 convs를 생성해야하는 모든 경우를 다 넣으면 된다. 


      
      //여기까지 넘어온 건 ai댓글 달려야 할 때 
      const convs = parent.conversation?.map((item) => {
        const content: any[] = [{ type: 'text', text: item.content }]
        if (item.imageUrl) {
          content.push({
            type: 'image_url',
            image_url: {
              url: item.imageUrl,
            },
          })
        }
        return {
          role: item.commenterType == 'user' ? 'user' : 'assistant',
          content: [{ type: 'text', text: item.content }],
        }
      })

      //console.log("최종적으로 넘기는 convs..")
      //console.log(convs)

      // console.log("오랜만에 하는 content 체크: ")
      // console.log(content.emotion)

      let tmpEmotion: string = ''

      if(content.emotion){
        tmpEmotion = content.emotion
      }

      
      const returnText = await chatComplete(
        parent.userId,
        dto.content,
        convs,
        undefined,
        bio,
        tmpEmotion
      )


      if (returnText) { //api가 답변을 생성한 이후의 과정 

        //console.log("returnText:")
        //console.log(returnText)
        let commentText = await postProcessComment(returnText)
        //console.log("after process text:")
        //console.log(commentText)

        const commentBody2: CommentDisplayType = {
          commentId: v4(),
          postId: parent.postId,
          content: commentText,
          userId: parent.userId,
          parentCommentId: recommentId,
          createdBy: 'ai',
          createdAt: new Date().toISOString(),
          likesCount: 0,
          ownerId: userId,
        }
        const res2 = await CommentService.InsertComment(commentBody2) //이건 그냥 db에 새로운 comment 넣는 경우. 어차피 여기서 들어가는 애들은 다 reply다. 
        
        
        if(!res2 && content.comments){

          //디폴트로 
          //console.log("지금 내게 필요한 뉴컨텐트:")
          //console.log(newContent)
          const parentComment = content.comments.find(
            (doc) => doc.userId == parent.userId
          )
         

          //console.log("parentcomment..") //ai가 했을때는 parentcomment가 없다 
          //console.log(parentComment)


          if(content.createdBy == 'user' || content.createdBy == null){
            
            if(parentComment && parentComment.user){
              const totalComments = await insertUnderComment(
                newContent,
                commentBody2,
                parentComment.user,
                commentBody.commentId
              )
  
              
  
            
              //console.log("totalComments 출력:")
              //console.log(totalComments)
  
              //console.log("newContent3:")
              //console.log(newContent)
  
              queryClient.setQueryData(parent.postId, { //여기에서 totalCOmments라고 생성된 댓글들까지 다 받은 다음에 다시 한번 setContent를 해주는 것 같다. 
                ...newContent,
                comments: totalComments,
              })
              setContent({
                ...newContent,
                comments: totalComments,
              })
  
            }
          } else if(content.createdBy == 'ai'){

            const totalComments = await insertUnderComment(
              newContent,
              commentBody2,
              content.user,
              commentBody.commentId
            )

            //console.log("totalComments 출력:")
            //console.log(totalComments)

            //console.log("newContent3:")
            //console.log(newContent)

            queryClient.setQueryData(parent.postId, { //여기에서 totalCOmments라고 생성된 댓글들까지 다 받은 다음에 다시 한번 setContent를 해주는 것 같다. 
              ...newContent,
              comments: totalComments,
            })
            setContent({
              ...newContent,
              comments: totalComments,
            })

          }


        

         
          
        }

        /*
        if (!res2 && content.comments && (content.createdBy == 'user' || content.createdBy == null)) { //내가 쓴 포스트 처리하는 것일때 

          const parentComment = content.comments.find(
            (doc) => doc.userId == parent.userId
          )

          if (parentComment && parentComment.user) {
            const totalComments = await insertUnderComment(
              newContent,
              commentBody2,
              parentComment.user,
              commentBody.commentId
            )

            

            queryClient.setQueryData(parent.postId, { //여기에서 totalCOmments라고 생성된 댓글들까지 다 받은 다음에 다시 한번 setContent를 해주는 것 같다. 
              ...newContent,
              comments: totalComments,
            })
            setContent({
              ...newContent,
              comments: totalComments,
            })
          }
        } 
        
        if (!res2 && content.createdBy == "ai") { //ai가 쓴 포스트에 댓글혹은 답글이 달린 것일때 
          console.log("so this post was by ai..")
          if(content.comments?.length == 0){
            console.log("첫 번째로 단 댓글이군요. 축하해요!")

          }

          const parentComment = content.comments?.find(
            (doc) => doc.userId == parent.userId
          )

          if (parentComment && parentComment.user) {
            const totalComments = await insertUnderComment(
              newContent,
              commentBody2,
              parentComment.user,
              commentBody.commentId
            )

            console.log("totalComments 출력:")
            console.log(totalComments)

            console.log("newContent4:")
            console.log(newContent)

            queryClient.setQueryData(parent.postId, { //여기에서 totalCOmments라고 생성된 댓글들까지 다 받은 다음에 다시 한번 setContent를 해주는 것 같다. 
              ...newContent,
              comments: totalComments,
            })
            setContent({
              ...newContent,
              comments: totalComments,
            })
          }
        }
        */
      }


      return
    }
  }

  const onSubmit = async (dto: CreatePostDto) => { //얘는 뭘 하는 함수일까? 

    //console.log("you're selected emotions is : " + selectedEmotion)

    // console.log("제목: "+dto.title)
    // console.log("본문 : "+dto.content)

    
    
    const blockList = await BlockService.GetBlocks(['blockerId'], [userId])
    let blockedList: string[] = []
    if (blockList && blockList.length > 0) {
      blockedList = blockList.map((item) => item.blockedId)
    }

    if (!dto.content || dto.content.length < 2) {
      alert('You should write something to post.')
      return
    }
    if (dto.content.length > 5000) {
      alert('You wrote more than 5000 characters.')
      return
    }

    if (parentId && parent) {
      postComment(dto)
      navigate(-1)
      return
    }

    let url = ''
    if (imgFile) {
      // 파일 이름 생성 (고유한 이름을 위해 타임스탬프 사용)
      const fileName = `${Date.now()}_${imgFile.name}`
      // Supabase Storage에 파일 업로드
      const { data, error } = await supabase.storage
        .from('post_images')
        .upload(fileName, imgFile)
      if (error) throw error
      url =
        process.env.REACT_APP_SUPABASE_URL +
        '/storage/v1/object/public/' +
        data.fullPath
    }

    const postId = v4()


    
    let encryptedContent: string;
    let body: PostQueryType;

    let privacyUserList = ['8e9c991e-6f3d-4916-bc3a-de5020308d5a', 'e8e0d2b4-7f50-4489-aed0-773781be8db1', 'a4aa2c55-03a6-4c73-8cc5-beb6ff741a00']

    if(privacyUserList.includes(userId)){
      try {
        encryptedContent = encryptData(dto.content);
        body = {
          postId: postId,
          createdAt: new Date().toISOString(),
          userId: userId,
          content: encryptedContent,
          likesCount: 0,
          imageUrl: url ? url : null,
          isEncrypted: true,
          emotion: selectedEmotion,
          title: dto.title
        };
      } catch (error) {
        console.error('Error encrypting content:', error);
        // Handle encryption error (e.g., show an error message to the user)
        return;
      }
    } else {


      encryptedContent = dto.content

      if(todayQuestion!=null){
        body = {
          postId: postId,
          createdAt: new Date().toISOString(),
          userId: userId,
          content: encryptedContent,
          likesCount: 0,
          imageUrl: url ? url : null,
          isEncrypted: false,
          emotion: selectedEmotion,
          title: todayQuestion
        };
      } else {
        body = {
          postId: postId,
          createdAt: new Date().toISOString(),
          userId: userId,
          content: encryptedContent,
          likesCount: 0,
          imageUrl: url ? url : null,
          isEncrypted: false,
          emotion: selectedEmotion,
          title: dto.title
        };
      }
      
    }

    

    const res = await PostService.InsertPost(body)
    //console.log("res:")
    //console.log(res)

    const mainPosts = queryClient.getQueryData(mainPostQueryKey) as any

    const checkLogId = sessionStorage.getItem('logId')
    if (checkLogId) {
      if (
        mainPosts.pages.flat().length == 0 ||
        !mainPosts ||
        !mainPosts.pages
      ) {
        const nation = await checkNationality(userId, name, email, dto.content)
        const body = {
          logId: checkLogId,
          isPost: true,
          nation: nation ?? '',
        }
        FunnelService.Update('logId', body)
      }
    }

    const pageParams = mainPosts?.pageParams ? [...mainPosts.pageParams] : [];
    const newPage = {
      ...body,
      user: {
        userId: userId,
        name: name,
        profileImageUrl: imgUrl,
      },
      comments: [],
      previews: [],
    };

    if (mainPosts && mainPosts.pages && mainPosts.pages.flat().length > 0) {
      queryClient.setQueryData(mainPostQueryKey, {
        pages: [newPage, ...mainPosts.pages.flat()],
        pageParams,
      });
    } else {
      queryClient.setQueryData(mainPostQueryKey, {
        pages: [newPage],
        pageParams,
      });
    }

    var secs: number[] = getRandomSeconds(
      PERSONAS.filter((doc) => !blockedList.includes(doc.id)).length
    )
    
    // console.log("random seconds:")
    // console.log(secs)

    secs = [1000, 4000, 7000, 10000, 13000, 16000, 19000, 22000, 25000, 28000]

    const { data: singleModeData, error: singleModeError } = await supabase
    .from('user')
    .select('singleMode')
    .eq('user_id', userId);


    var tmpSingleValue = false; 

    if(singleModeData!=null){
     tmpSingleValue = singleModeData[0].singleMode
    } 


    if(submissionType == 'withAI'){

      PERSONAS.filter((doc) => !blockedList.includes(doc.id)).forEach(
        async (doc, index) => {
  
          if (tmpSingleValue && doc.id !== 'ccf3a635-c85d-40c1-b977-437de8943a4d') {
            return; // Skip the loop iteration if not the specific PERSONA
          }
  
          if(((tmpSingleValue == false) || (tmpSingleValue == null)) && doc.id == 'ccf3a635-c85d-40c1-b977-437de8943a4d'){
            return; 
          }
  
          const sec = secs[index]
          setTimeout(async () => {
  
            let returnText = ''
            var tmpDocID; 
            
  
            tmpDocID  = doc.id; 
  
  
            if(todayQuestion!=null){
              returnText = await chatComplete(
                doc.id,
                dto.content,
                [],
                url,
                bio,
                selectedEmotion,
                todayQuestion,
                userId 
              )
            } else {
              returnText = await chatComplete(
                doc.id,
                dto.content,
                [],
                url,
                bio,
                selectedEmotion,
                dto.title,
                userId 
              )
            }
  
            
  
            if (returnText) {
  
              
              let commentText = await postProcessComment(returnText)
  
              const cmtId = v4()
              const commentBodyQuery: CommentQueryType = {
                commentId: cmtId,
                createdAt: new Date().toISOString(),
                content: commentText,
                postId: postId,
                createdBy: 'ai',
                parentCommentId: undefined,
                status: undefined,
                likesCount: 0,
                userId: doc.id,
                ownerId: userId,
                likedByUser: false,
              }
              const res = await CommentService.InsertComment(commentBodyQuery)
  
              const commentBody: any = {
                ...commentBodyQuery,
                user: {
                  name: doc.name,
                  userId: doc.id,
                  profileImageUrl: doc.profileImageUrl,
                },
              }
  
              // 배열의 마지막 요소인지 확인
              if (sec == Math.max(...secs)) {
                Snackbar.show({
                  text: SnackBarTypes.POSTED,
                  onClick: () => navigate(`/post/${postId}`),
                })
              }
  
              if (
                sec == Math.min(...secs) ||
                sec == [...secs].sort((a, b) => a - b)[1]
              ) {
                const mainPosts = queryClient.getQueryData(
                  mainPostQueryKey
                ) as any
  
                if (mainPosts.pages.flat().length > 0) {
                  const addedPosts = mainPosts.pages.flat().map((item: any) => {
                    if (item.postId == postId) {
                      return {
                        ...item,
                        previews: [...item.previews, commentBody],
                        comments: [...item.comments, cmtId],
                      }
                    } else return { ...item }
                  })
                  // Home에 바로 넣기
                  queryClient.setQueryData(mainPostQueryKey, {
                    pages: [...addedPosts],
                    pageParams: [...mainPosts.pageParams],
                  })
                }
                // !Is refetch okay?
                // queryClient.refetchQueries([mainPostQueryKey])
              }
  
              const detailPost = queryClient.getQueryData(postId) as any
  
              if (detailPost) {
                // Home에 바로 넣기
                queryClient.setQueryData(postId, {
                  ...detailPost,
                  comments: [...detailPost.comments, commentBody],
                })
              }
            }
          }, sec)
  
         
        }
  
       
      )

    }
    

    if(submissionType == 'withAI') {
      Snackbar.show({ text: SnackBarTypes.POSTING })
      if (!res) {
        reset({
          content: '',
        })
      }
    } 

    else if(submissionType == 'withoutAI'){
      Snackbar.show({ text: SnackBarTypes.POSTINGAI})
      if (!res) {
        reset({
          content: '',
        })
      }
    }
    
   
    navigate('/')
    
    
  }

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    const validExtensions = [
      'image/heic',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/webp',
    ]
    if (!validExtensions.includes(file.type)) {
      Snackbar.show({
        text: SnackBarTypes.FILETYPE,
      })
      return
    }
    if (parseInt((file.size / 1024).toFixed(2)) > 3000) {
      Snackbar.show({
        text: SnackBarTypes.FILESIZE,
      })
      return
    }

    try {
      const imageUrl = URL.createObjectURL(file)
      setUploadedImage(imageUrl)
      setImgFile(file)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  let placeHolder: string = '';

  if(parent && parentId){
    placeHolder = 'Reply to continue the conversation!'
  } else {

    if(todayQuestion==null){
      placeHolder = 'How was your day? Capture your moments.'
    } else {
      placeHolder = "Share your response to today's question!"
    }
    
  }

  return (
    <div className='relative'>
      <div className='grid gap-2 grid-flex-row grid-cols-8 border-b border-b-gray-100 w-full px-4 py-2 h-[50px] justify-center content-center'>
        <div
          className='col-span-2 text-[14px] content-center cursor-pointer text-gray-800 hover:text-black'
          onClick={() => {
            navigate(-1)
          }}
        >
          Cancel
        </div>
        <div className='col-span-4 text-center font-semibold text-[16px] content-center'>
          {parentId && parent ? 'Reply' : 'New Journal'}
        </div>
        <div className='col-span-2 justify-end content-end flex'>
          {/* <FeedbackModal>Feedback</FeedbackModal> */}
        </div>
      </div>
      {parentId && parent && (
        <>
          <ParentContent />
        </>
      )}
      <form onSubmit={handleSubmit(onSubmit)} className='pb-2'>
        <div className='p-2'>
          <div className='flex flex-row pt-1'>
            <div className='flex justify-end items-start p-0 pt-1 pl-2'>
              <img
                src={imgUrl ? imgUrl : '/svg/profile.svg'}
                className='w-[34px] rounded-full'
              />
            </div>
            <div className='ml-2 w-full'>
              <div className='flex flex-row content-center'>
                <div className='font-bold'>{name}</div>
                <div className='text-gray-400 ml-2 text-sm content-center'>
                  {formatDate(new Date())}
                </div>
              </div>
              <div>
              
              {(!(parentId && parent) && todayQuestion==null) && (
                  <Controller
                    control={control}
                    name='title'
                    render={({ field, fieldState }) => (
                      <TextareaAutosize
                        {...field}
                        autoFocus
                        placeholder='Title'
                        className='w-full mt-0 text-[19px] pb-3 focus:outline-none resize-none placeholder:text-gray-300'
                        maxRows={1}
                        onKeyDown={e => {
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        maxLength={45}
                        
                      />
                    )}
                  />
              )}

              {(!(parentId && parent) && todayQuestion==null) && (
                <Controller
                control={control}
                name='content'
                rules={{
                  required: 'The input is required',
                }}
                render={({ field, fieldState }) => (
                  
                  <TextareaAutosize
                    {...field}
                    
                    placeholder={placeHolder}
                    className='w-full mt-0 text-[16px] pb-3 focus:outline-none resize-none placeholder:text-gray-300'
                    minRows={2}
                  />
                )}
                />
              )}


              {(!(parentId && parent) && todayQuestion!=null) && (
                  <Controller
                    control={control}
                    name='title'
                    render={({ field, fieldState }) => (
                      <TextareaAutosize
                        {...field}
                        placeholder='Title'
                        className='w-full mt-0 text-[19px] pb-3 focus:outline-none resize-none placeholder:text-gray-300'
                        maxRows={1}
                        onKeyDown={e => {
                          if (e.key === 'Enter') e.preventDefault();
                        }}
                        maxLength={45}
                        value={todayQuestion}
                      />
                    )}
                  />
              )}

              {(!(parentId && parent) && todayQuestion!=null) && (
                <Controller
                control={control}
                name='content'
                rules={{
                  required: 'The input is required',
                }}
                render={({ field, fieldState }) => (
                  
                  <TextareaAutosize
                    {...field}
                    autoFocus
                    placeholder={placeHolder}
                    className='w-full mt-0 text-[16px] pb-3 focus:outline-none resize-none placeholder:text-gray-300'
                    minRows={2}
                  />
                )}
                />
              )}


              {(parentId && parent) && (
                <Controller
                control={control}
                name='content'
                rules={{
                  required: 'The input is required',
                }}
                render={({ field, fieldState }) => (
                  
                  <TextareaAutosize
                    {...field}
                    autoFocus
                    placeholder={placeHolder}
                    className='w-full mt-0 text-[16px] pb-3 focus:outline-none resize-none placeholder:text-gray-300'
                    minRows={2}
                  />
                )}
                />
              )}
              
                
                

                {!parent && !parentId && (
                  <div className='w-full flex flex-row'>
                    {uploadedImage ? (
                      <div className='relative max-w-[100%]'>
                        <img
                          src={uploadedImage}
                          className='max-h-[260px] rounded-[12px] border'
                        />
                        <div
                          className='cursor-pointer absolute top-1 right-1 rounded-full bg-gray-900/60 p-1 hover:bg-gray-900/80'
                          onClick={() => {
                            setUploadedImage('')
                            setImgFile(null)
                          }}
                        >
                          <X color='white' size={16} />
                        </div>
                      </div>
                    ) : (
                      <>
                        <input
                          type='file'
                          accept='.heic,image/*'
                          onChange={handleFileUpload}
                          id='image-upload'
                          className='hidden'
                        />
                        <label
                          htmlFor='image-upload'
                          className='flex flex-row cursor-pointer justify-center items-center overflow-hidden text-[#919191] hover:text-[#515151] transition duration-200'
                        >
                          <Images
                            className='cursor-pointer'
                            strokeWidth={1.4}
                            size={18}
                          />
                          <p className='pl-1'>Upload image </p>
                        </label>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className={`w-full pt-16 px-4 flex justify-end`}>
          <div></div>
        
          <button
            type='submit'
            style={{ transition: '0.2s ease' }}
            disabled={isSubmitting}
            className={`hover:bg-gray-200 active:scale-90 rounded-[120px] py-2 mr-2 text-center bg-gray-300 text-white font-semibold ${(isSubmitting && submissionType=='withoutAI') ? 'w-[110px]' : 'w-[150px]'}`}
            onClick={() => setSubmissionType('withoutAI')}
          >
            {(isSubmitting && submissionType=='withoutAI') ? 'Posting..' : 'Post without AI'}
          </button>


          <button
            type='submit'
            style={{ transition: '0.2s ease' }}
            disabled={isSubmitting}
            className={`hover:bg-gray-800 active:scale-90 rounded-[120px] py-2 text-center bg-black text-white font-semibold ${(isSubmitting && submissionType=='withAI')? 'w-[110px]' : 'w-[150px]'}`}
            onClick = {() => setSubmissionType('withAI')}
          >
            {(isSubmitting && submissionType=='withAI') ? 'Posting..' : 'Post with AI'}
          </button>
        </div>
        {!parentId && <Emotions onClick={(emotion) => setSelectedEmotion(emotion)} />}
        
        {/*{!parentId && <Examples onClick={(val) => setValue('content', val)} />}*/}
      </form>
    </div>
  )
}

export default withAnimation(React.memo(WritePage))