import React, { useState } from 'react'
import { PostDisplayType, PostType } from '@/services/post-service/types'
import PostDisplay from '../organisms/PostDisplay'
import {
  formatDate,
  formatDateMMDDYY,
  isSameDay,
  isToday,
} from '../../utils/formatDate'
import { decryptData } from '../../utils/crypto';
import QuestionSuggest from './QuestionSuggest';
import QuestionAdvanceSuggest from './QuestionAdvanceSuggest';
import StreakBox from './StreakBox';
import { isMobile } from 'react-device-detect';

type MainPostListProps = {
  posts: PostDisplayType[] | undefined
}

const MainPostList = ({ posts }: MainPostListProps) => {




  return (
    <div>
      {posts && posts.length > 0 && isToday(new Date(posts[0].createdAt)) && (
        <>
          
          {/*<div className='mt-2 mb-1 font-bold text-[2.4em] px-4'>Today</div>
          <StreakBox />*/}

          {isMobile ?  <div className="flex items-center mt-2 mb-1 font-bold text-[2.4em] pl-5 justify-between w-[96%]">
            <span>Today</span>
             
            <StreakBox />

          </div> : 
           <div className="flex items-center mt-2 mb-1 font-bold text-[2.4em] px-0 justify-between w-[100%]">
           <span>Today</span>
            
           <StreakBox />

         </div>

          }
          
          
         



        </>
        
      )}
      
      {posts && posts.length > 0 && !isToday(new Date(posts[0].createdAt)) && (


        <>


         {isMobile ? <div className="flex items-center mt-2 mb-1  pl-5 justify-between w-[96%]">
            <div className='text-gray-400 mt-2 mb-2 text-[1.4em] px-4'>
              {formatDateMMDDYY(new Date(posts[0].createdAt))}
            </div>
             
            <StreakBox />

          </div> : 
          
          <div className="flex items-center mt-2 mb-1  pl-5 justify-between w-[96%]">
            <div className='text-gray-400 mt-2 mb-2 text-[1.4em] px-4'>
              {formatDateMMDDYY(new Date(posts[0].createdAt))}
            </div>
             
            <StreakBox />

          </div>} 
         
        
        
        
        </>
      )}

      {posts && posts.length > 0 && (
        <>
          <QuestionSuggest posts={posts}/>
        </>
      )}
      
      {/*<QuestionAdvanceSuggest />*/}
      <br />
      {posts &&
        posts.map((post, index) => {

          return (
            <>
              {index > 0 &&
                !isSameDay(
                  new Date(posts[index - 1].createdAt),
                  new Date(post.createdAt)
                ) && (
                  <div className='text-gray-400 mt-2 mb-2 text-[1.4em] px-4'>
                    {formatDateMMDDYY(new Date(post.createdAt))}
                  </div>
                )}
              <PostDisplay key={post.postId} post={post} />
              {/* {posts.length != 0 && (
                <div
                  key={post.postId}
                  className='border-b-[0.5px] border-b-slate-300'
                ></div>
              )} */}
            </>
          )
        })}
    </div>
  )
}

export default React.memo(MainPostList)
