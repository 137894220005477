import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../store/useUserStore'
import { ChevronLeft, ListFilter } from 'lucide-react'
import ProfileTop from '../components/organisms/ProfileTop'
import { UserInfoType } from './PersonaProfilePage'
import MenuOptions from '../components/morecules/MenuOptions'
import ProfilePosts from '../components/organisms/profile/ProfilePosts'
import ProfileReplies from '../components/organisms/profile/ProfileReplies'
import CalendarPosts from '../components/organisms/profile/CalendarPosts'
import EditProfile from '../components/organisms/EditProfile'
import ProfileLikes from '../components/organisms/profile/ProfileLikes'
import { useQuery } from 'react-query'
import { supabase } from '../services/supabase'
import { PERSONAS } from '../utils/prompts'
import { Calendar, CalendarProps } from 'react-calendar'
//import 'react-calendar/dist/Calendar.css';
import './calendar.css'


enum Categories {
  POSTS = 'Journals',
  REPLIES = 'Replies',
  LIKED = 'Liked',
}

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
type EmotionsByDate = Record<string, string>;

const emotionEmojiMap: Record<string, string> = {
  'Happy': '😊',
  'Bored': '🥱',
  'Confused': '😵‍💫',
  'Angry': '😡',
  'Lonely': '😿',
  'Sad': '😭',
  'Tired': '🛌',
  'Passionate': '🔥',
  'Anxious': '😨',
  'No emotion': '🍀'
};


const ProfilePage = () => {
  const [category, setCategory] = useState<Categories>(Categories.POSTS)
  const [isOpenEditProfile, setIsOpenEditProfile] = useState<boolean>(false)
  const [emotionsByDate, setEmotionsByDate] = useState<EmotionsByDate>({})
  const navigate = useNavigate()
  const { userId, name, imgUrl, bio } = useUserStore()
  const [activeStartDate, setActiveStartDate] = useState<Date | null>(null);

  const [value, onChange] = useState<Value>(new Date());
  
  const handleChange = (val: Value) => {
    const dateValue = convertValueToDate(val);
    return dateValue

  }

  const getEndTime = (val: Value) => {
    const dateValue = convertValueToDate(val);
    dateValue?.setHours(23, 59, 59, 999)
    const tmpValue = dateValue?.toISOString()
    return tmpValue
  }
  
  const getStartTime = (val: Value) => {
    const dateValue = convertValueToDate(val);
    dateValue?.setHours(0, 0, 0, 0)
    const tmpValue = dateValue?.toISOString()
    return tmpValue
  }

  const convertValueToDate = (value: Value): Date | null => {
    if (value instanceof Date) {
      return value;
    } else if (Array.isArray(value) && value[0] instanceof Date) {
      return value[0];
    }
    return null;
  };

  const getMonthStartAndEnd = (date: Date) => {
    const start = new Date(date.getFullYear(), date.getMonth(), 1);
    const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    end.setHours(23, 59, 59, 999);
    return { start, end };
  }

  const fetchEmotionsForMonth = useCallback(async (date: Date) => {
    const { start, end } = getMonthStartAndEnd(date);
  
    const { data: posts, error } = await supabase
      .from('post')
      .select('created_at, emotion')
      .eq('user_id', userId)
      .is('status', null)
      .gte('created_at', start.toISOString())
      .lte('created_at', end.toISOString())
      .order('created_at', { ascending: true }); //제일 최근 포스트 감정 사용
  
    if (error) {
      console.error('Error fetching emotions:', error);
      setEmotionsByDate({});
    } else {
      const emotionsMap = posts.reduce((acc: EmotionsByDate, post) => {
        const postDate = new Date(post.created_at);
        // 사용자 타임존 사용하기
        const localDate = new Date(postDate.getTime() - postDate.getTimezoneOffset() * 60000);
        const localDateString = localDate.toISOString().split('T')[0];
  
        // 제일 최근 포스트가 감정기록 없더라도 있는 포스트 있다면 그거 보여주기
        if (post.emotion || !acc[localDateString]) {
          acc[localDateString] = post.emotion || 'No Emotion';
        }
  
        return acc;
      }, {});
  
      setEmotionsByDate(emotionsMap);
    }
  }, [userId]);

  const userInfo = useMemo((): UserInfoType => {
    return {
      bio: bio,
      name: name,
      profileImageUrl: imgUrl,
    }
  }, [userId, bio, name, imgUrl])

  const fetchDetails = useCallback(async () => {
    const { count: postCount } = await supabase
      .from('post')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .filter('status', 'is', null)

    const { count: replyCount } = await supabase
      .from('comment')
      .select('*', { count: 'exact', head: true })
      .eq('user_id', userId)
      .eq('owner_id', userId)
      .filter('status', 'is', null)

    return {
      postCount: postCount,
      replyCount: replyCount,
    }
  }, [userId])

  const { data: count, isLoading, isError } = useQuery('usages', fetchDetails)

  useEffect(() => {
    const currentDate = activeStartDate || convertValueToDate(value) || new Date();
    fetchEmotionsForMonth(currentDate);
  }, [activeStartDate, value, fetchEmotionsForMonth]);

  const handleActiveStartDateChange = ({ activeStartDate }: { activeStartDate: Date | null }) => {
    if (activeStartDate) {
      setActiveStartDate(activeStartDate);
    }
  };

  const tileContent = ({ date, view }: { date: Date, view: string }) => {
    if (view === 'month') {
      const dateKey = date.toISOString().split('T')[0];
      const emotion = emotionsByDate[dateKey];
      const emoji = emotion ? emotionEmojiMap[emotion] || '🍀' : '';
      
      return (
        <div className='emotion-tile'>
          {emoji ? <span>{emoji}</span> : null}
        </div>
      );
    }
    return null;
  };

  return (
    <div className='max-h-[100vh]'>
      <div className='px-4 pb-2 pt-4 flex flex-row justify-between'>
        <div>
          <button
            onClick={() => {
              navigate(-1)
            }}
          >
            <ChevronLeft />
          </button>
        </div>
        <div>
          <button onClick={() => navigate('/setting')}>
            <ListFilter size='22' className='mt-[-2px]' />
          </button>
        </div>
      </div>
      <ProfileTop userInfo={userInfo} />
      <div className='px-4'>
        <div className='mt-4 text-gray-400 text-[0.9em]'>
          <div>
            {count?.postCount} posts · {count?.replyCount} replies ·{' '}
            {PERSONAS.length} agents
          </div>
        </div>
        <div>
          <button
            onClick={() => {
              setIsOpenEditProfile(true)
            }}
            className={`mt-4 border border-gray-300 rounded-[8px] w-full p-1 font-medium bg-white text-black`}
          >
            Edit Profile
          </button>
        </div>
      </div>
      <div className='mt-4 relative w-full'>
        <div
          style={{
            transition: '0.3s ease',
            left:
              category == Categories.POSTS
                ? '5%'
                : category == Categories.REPLIES
                  ? '35%'
                  : '65%',
          }}
          className={`border-black border-b-[1px] absolute bottom-0 w-[30vw] max-w-[180px]`}
        ></div>
        <MenuOptions
          category={category}
          setCategory={(cat) => setCategory(cat as Categories)}
          Categories={Object.values(Categories)}
        />
      </div>
      <div className='pt-1 relative pb-12'>
        {category == Categories.POSTS && 
        <>
            <Calendar className={"custom-calendar button"} value={value} onChange={onChange} tileContent={tileContent} onActiveStartDateChange={handleActiveStartDateChange}/>
            <br />
            <CalendarPosts startTime={getStartTime(value)} endTime={getEndTime(value)} />

        </>
        }
      
        {category == Categories.REPLIES && <ProfileReplies />}
        {category == Categories.LIKED && <ProfileLikes />}
      </div>
      <EditProfile open={isOpenEditProfile} setOpen={setIsOpenEditProfile} />
    </div>
  )
}

export default React.memo(ProfilePage)