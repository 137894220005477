import React, { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from '../atom/ui/alert-dialog';
import { useUserStore } from '../../store/useUserStore';
import TextareaAutosize from 'react-textarea-autosize';
import { supabase } from '../../services/supabase';
import Snackbar from './SnackBar';
import { SnackBarTypes } from './SnackBarUI';

/*
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../../services/supabase'
*/

type FeedbackModalProps = {
  children: React.ReactNode;
  desc?: string;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
};

const AgentSettingModal = ({
  children,
  desc = 'If you turn on single agent mode, you will only receive comments from Melonn. \n \n Single agent mode is recommended for users who prefer a calmer and more focused journal writing experience.',
  isOpen,
  setIsOpen,
}: FeedbackModalProps) => {
  const { userId, name } = useUserStore();
  const [text, setText] = useState<string>();
  const [isSingleAgentMode, setIsSingleAgentMode] = useState<boolean>(false); // State for the toggle

  //console.log("this user's single agent mode is: ")
  //console.log(singleMode)

  
  useEffect(() => {

    const checkSingleMode = async () => {

      const { data: singleModeData, error: singleModeError } = await supabase
      .from('user')
      .select('singleMode')
      .eq('user_id', userId);

      if(singleModeData != null){
        console.log(singleModeData[0].singleMode);
        setIsSingleAgentMode(singleModeData[0].singleMode)
      }


    }



    checkSingleMode(); // Call the function to fetch data
  }, []); 
  
 

  return (
    <AlertDialog open={isOpen} onOpenChange={setIsOpen}>
      <AlertDialogTrigger className='cursor-pointer hover:bg-slate-50 py-1 px-2 rounded-[4px]'>
        {children}
      </AlertDialogTrigger>
      <AlertDialogContent className='bg-white/100 w-[90%] rounded-[12px] sm:rounded-[12px] overflow-hidden pb-4 border-none'>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-[20px] font-medium sm:text-center'>
            Agent Settings
          </AlertDialogTitle>
          <div className='text-[15px] sm:text-center'>
            <div>{desc}</div>
            <div className='flex items-center justify-center mt-4 sm:text-center'>
            <label className='mr-2'>Single Agent Mode:</label>
            <input
              type='checkbox'
              checked={isSingleAgentMode}
              onChange={() => setIsSingleAgentMode((prev) => !prev)}
              className='cursor-pointer accent-green-600'
            />
          </div>

            
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter className='justify-end w-full flex flex-row items-center'>
          <AlertDialogCancel className='border-none p-2 px-4 m-0 rounded-[4px] font-normal'>
            Cancel
          </AlertDialogCancel>
          <AlertDialogAction
            className='border-none p-2 px-8 ml-2 bg-black text-white rounded-[4px] hover:bg-gray-800 font-medium'
            onClick={async () => {
              console.log('current mode is: '+isSingleAgentMode)

              //유저 에이전트 설정 업데이트하기 
              const { error } = await supabase
              .from('user')
              .update({ singleMode: isSingleAgentMode })
              .eq('user_id', userId)
            }}
          >
            Save
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};

export default React.memo(AgentSettingModal);
