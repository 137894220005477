import React, { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { BicepsFlexed, Check, Loader, X } from 'lucide-react'
import { Link } from 'react-router-dom'
import Snackbar from './SnackBar'

export type SnackbarShowParams = {
  text: SnackBarTypes
  onClick?: () => void
}

export type SnackbarHideParams = {}

let timeId: any
const SnackbarUI = forwardRef((props, ref) => {
  const [show, setShow] = useState(false)
  const [text, setText] = useState<SnackBarTypes>(SnackBarTypes.POSTING)
  const [func, setFunc] = useState<() => void | undefined>()

  const hideSnackbar = () => {
    setShow(false)
  }

  useImperativeHandle(ref, () => ({
    show: (params: SnackbarShowParams) => {
      if (timeId) {
        setShow(false)
        clearTimeout(timeId)
      }

      setTimeout(() => {
        setText(params.text)
        if (params.onClick) {
          setFunc(() => params.onClick)
        }
        timeId = setTimeout(() => hideSnackbar(), 5000)
        setShow(true)
      }, 100)
    },
    hide: () => setShow(false),
  }))

  return (
    <div
      className='fixed z-50 w-full left-0 top-[3px] opacity-0 p-2 h-[20px] flex justify-center items-start animate-slideTopIn'
      style={{ display: show ? 'flex' : 'none' }}
    >
      <div
        onClick={() => {
          if (text == SnackBarTypes.POSTED && func) {
            func()
            setFunc(undefined)
            Snackbar.hide()
          } else {
            Snackbar.hide()
          }
        }}
        className='p-3 cursor-pointer w-full max-w-[600px] bg-black/85 backdrop-blur-[4px] border-[0.5px] rounded-[16px] text-[15px] flex flex-row items-center text-white justify-between'
      >
        <div className='w-[90%] flex flex-row items-center'>
          <div className='icon'>{SnackBars[text].icon}</div>
          <div className='ml-2'>
            <div>{SnackBars[text].text}</div>
            {/* {text == SnackBarTypes.POSTING && (
              <div className='mt-0 text-[12px]'>It could take a few seconds</div>
            )} */}
          </div>
        </div>
        {SnackBars[text].subText && (
          <div className='ml-2 mr-2'>{SnackBars[text].subText}</div>
        )}
      </div>
    </div>
  )
})

export default SnackbarUI

export enum SnackBarTypes {
  POSTING = 'POSTING',
  POSTED = 'POSTED',
  FEEDBACK = 'FEEDBACK',
  DELETE = 'DELETE',
  FILESIZE = 'FILESIZE',
  FILETYPE = 'FILETYPE',
  POSTINGAI = 'POSTINGAI'
}

export const SnackBars = {
  POSTING: {
    text: 'Friends are viewing your post...',
    subText: <X size={18} />,
    icon: <Loader color='white' className='animate-spin w-5' />,
  },
  POSTINGAI: {
    text: 'Your journal has been recorded!',
    subText: <X size={18} />,
    icon: <Check color='white' className='w-5' />,
  },
  POSTED: {
    text: 'Your post received much attention!',
    subText: 'View',
    icon: <Check color='white' className='w-5' />,
  },
  FEEDBACK: {
    text: 'Thanks for your feedback',
    subText: '',
    icon: <></>,
  },
  DELETE: {
    text: 'The content is deleted.',
    subText: '',
    icon: <></>,
  },
  FILESIZE: {
    text: 'Sorry you can only uploade image under 3MB',
    subText: '',
    icon: <></>,
  },
  FILETYPE: {
    text: 'Sorry you can only uploade JPEG, JPG, PNG, SVG',
    subText: '',
    icon: <></>,
  },
}
