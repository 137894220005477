import { useUserStore } from '../../store/useUserStore'
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import ProfileIcon from '../atom/ProfileIcon'
import Snackbar from '../morecules/SnackBar'
import { SnackBarTypes } from '../morecules/SnackBarUI'
import { UserService } from '../../services/user-service'
import { supabase } from '../../services/supabase'
import { checkValid } from './EditProfile'
import { isMobile } from 'react-device-detect'

type OnBoardingProps = {
  onOpen: Dispatch<SetStateAction<boolean>>
}

const OnBoarding = ({ onOpen }: OnBoardingProps) => {
  const { name, setName, setBio, setImgUrl, bio, imgUrl, userId } =
    useUserStore()
  const [profileName, setProfileName] = useState<string>(name)
  const [profileBio, setProfileBio] = useState<string>(bio)
  const [errorMsg, setErrorMsg] = useState<string>('')
  const [uploadedImage, setUploadedImage] = useState<string>(imgUrl)
  const [imgFile, setImgFile] = useState<any>(null)
  const [isUploading, setIsUploading] = useState<boolean>(false)
  const [page, setPage] = useState<number>(0)
  const [typed, setTyped] = useState<'A' | 'B'>('A')

  useEffect(() => {
    setProfileBio(bio)
    setProfileName(name)
    setUploadedImage(imgUrl)
  }, [name, imgUrl])

  useEffect(() => {
    const savedAorb = localStorage.getItem('aorb') as 'A' | 'B'
    if (savedAorb) setTyped(savedAorb)
    else {
      const aorb = Math.random() < 0.5 ? 'A' : 'B'
      localStorage.setItem('aorb', aorb)
      setTyped(aorb)
    }
  }, [])

  const handleFileUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (!file) return

    const validExtensions = [
      'image/heic',
      'image/jpeg',
      'image/png',
      'image/jpg',
      'image/webp',
    ]
    if (!validExtensions.includes(file.type)) {
      Snackbar.show({
        text: SnackBarTypes.FILETYPE,
      })
      return
    }
    if (parseInt((file.size / 1024).toFixed(2)) > 3000) {
      Snackbar.show({
        text: SnackBarTypes.FILESIZE,
      })
      return
    }

    try {
      const imageUrl = URL.createObjectURL(file)
      setUploadedImage(imageUrl)
      setImgFile(file)
    } catch (error) {
      console.error('Error uploading file:', error)
    }
  }

  const onSubmit = async () => {
    if (name == profileName && bio == profileBio) {
      onOpen(false)
      return
    }

    const error = checkValid(profileName)
    if (error) {
      setErrorMsg(error)
      return
    }

    let url = imgUrl
    if (imgFile) {
      // 파일 이름 생성 (고유한 이름을 위해 타임스탬프 사용)
      const fileName = `${Date.now()}_${imgFile.name}`
      // Supabase Storage에 파일 업로드
      const { data, error } = await supabase.storage
        .from('post_images')
        .upload(fileName, imgFile)
      if (error) throw error
      url =
        process.env.REACT_APP_SUPABASE_URL +
        '/storage/v1/object/public/' +
        data.fullPath
    }

    const body = {
      userId: userId,
      name: profileName,
      bio: profileBio,
      profileImageUrl: url,
    }
    const res = await UserService.UpdateUser('userId', body)

    if (!res) {
      setBio(profileBio)
      setName(profileName)
      onOpen(false)
    }
  }

  return (
    <div className='fixed flex py-8 top-0 left-0 items-center justify-center bg-white sm:bg-gray-100 w-[100vw] h-[100vh] z-20'>
      <div
        className={`w-[94%] sm:w-[600px] bg-white p-4 flex flex-col relative min-h-90vh`}
        // className={`${isMobile ? 'w-[100%] h-[100%]' : 'w-[94%]'} sm:w-[600px] bg-white p-4 flex flex-col relative`}
      >
        {page == 0 && (
          <>
            <div className='flex flex-col justify-between items-center'>
              <div>
                <div className='font-bold text-[32px] text-center z-10 mt-4'>
                  Welcome to <span className='text-[#01AB8E]'>Melonn.</span>
                  <br />
                  <div className='text-gray-400 text-[20px] font-medium'>
                    Melonn is your secure, private AI Journal. 
                    <br />
                    Be yourself! Express yourself freely, without any filters. 
                  </div>
                </div>
              </div>
              <div className='w-[100%] justify-center items-center text-center flex flex-col mb-8'>
                <img
                  src='/img/home.jpeg'
                  className='mt-8 rounded-[6px]  sm:mt-12'
                />
                <div
                  onClick={() => {
                    setPage(1)
                  }}
                  className='w-[100%] rounded-[6px] cursor-pointer bg-[#01AB8E] hover:bg-[#019B7E] p-2 text-center mt-4 text-white font-bold'
                >
                  Next
                </div>
              </div>
            </div>
          </>
        )}
        {page == 1 && (
          <>
            <div className='flex flex-col justify-between items-center'>
              <div>
                <div className='font-bold text-[32px] text-center z-10 mt-4'>
                  Meet our <span className='text-[#01AB8E]'>AI Agents.</span>
                  <br />
                  <div className='text-gray-400 text-[20px] font-medium'>
                    Post your journal and get responses 
                    <br />
                    from AIs with different personalities.
                  </div>
                </div>
              </div>
              <div className='w-[100%] justify-center items-center text-center flex flex-col mb-8'>
                <img
                  src='/img/onboarding-2.png'
                  className='mt-8 rounded-[6px]  sm:mt-12'
                />
                <div
                  onClick={() => {
                    setPage(2)
                  }}
                  className='w-[100%] rounded-[6px] cursor-pointer bg-[#01AB8E] hover:bg-[#019B7E] p-2 text-center mt-4 text-white font-bold'
                >
                  Next
                </div>
              </div>
            </div>
          </>
        )}
         {page == 2 && (
          <>
            <div className='flex flex-col justify-between items-center'>
              <div>
                <div className='font-bold text-[32px] text-center z-10 mt-4'>
                  Keep the <span className='text-[#01AB8E]'>Streak going.</span>
                  <br />
                  <div className='text-gray-400 text-[20px] font-medium'>
                    We will mark your calendar with a 🍀 every time you write. 
                    <br />
                    Emotion emojis are for journals with specific emotions. 
                  </div>
                </div>
              </div>
              <div className='w-[100%] justify-center items-center text-center flex flex-col mb-8'>
                <img
                  src='/img/onboarding-4.png'
                  className='mt-8 rounded-[6px]  sm:mt-12'
                />
                <div
                  onClick={() => {
                    setPage(3)
                  }}
                  className='w-[100%] rounded-[6px] cursor-pointer bg-[#01AB8E] hover:bg-[#019B7E] p-2 text-center mt-4 text-white font-bold'
                >
                  Next
                </div>
              </div>
            </div>
          </>
        )}
        {page == 3 && (
          <>
            <div className='font-semibold mt-2 text-center text-[20px]'>
              Before getting started,
              <br />
              please set up your profile for a better Melonn experience. 
            </div>
            <div className='flex flex-row w-[100%] justify-between mt-12'>
              <div className='w-[64%] mr-4'>
                <label className='font-medium text-[14px]'>Name</label>
                <input
                  onFocus={() => {
                    if (errorMsg) setErrorMsg('')
                  }}
                  autoFocus={false}
                  className='text-[15px] pt-1 pb-2 rounded-none border-b w-full border-b-gray-200 focus:border-b-black focus:outline-none transition duration-300 ease-in-out'
                  placeholder='+ Write name'
                  value={profileName}
                  maxLength={25}
                  onChange={(e) => {
                    setProfileName(e.currentTarget.value)
                  }}
                />
              </div>
              <div className='w-[30%] flex justify-center items-center'>
                {/* <div className='font-medium text-[14px] mt-4'>Profile image</div> */}
                <div className='w-[66px] flex justify-center items-center mt-2'>
                  <input
                    type='file'
                    accept='image/*'
                    onChange={handleFileUpload}
                    id='image-upload'
                    className='hidden'
                  />
                  <label
                    htmlFor='image-upload'
                    className='block cursor-pointer rounded-full overflow-hidden shadow-lg'
                  >
                    <img
                      src={uploadedImage}
                      className='rounded-full w-30 border-[3px]'
                    />
                  </label>
                </div>
              </div>
            </div>
            <div>
              <div className='w-full mt-4'>
                <label className='font-medium text-[14px]'>Bio</label>
                <TextareaAutosize
                  onFocus={() => {
                    if (errorMsg) setErrorMsg('')
                  }}
                  className='resize-none text-[15px] pt-1 pb-2 rounded-none border-b w-full border-b-gray-200 focus:border-b-black focus:outline-none transition duration-300 ease-in-out'
                  placeholder='+ Write bio'
                  value={profileBio}
                  onChange={(e) => {
                    setProfileBio(e.currentTarget.value)
                  }}
                  maxRows={5}
                  maxLength={120}
                />
              </div>
              <p className='text-gray-400 text-[14px]'>
                Please write anything about yourself. This will give our AI agents a 
                better understanding of who you are.
              </p>
              {errorMsg && (
                <div className='mt-2 text-left w-full text-[14px] text-[#ff0000]'>
                  {errorMsg}
                </div>
              )}
            </div>
            <div className='mt-12'>
              <div className='text-[14px] text-gray-600 text-left'>
                You can modify profile anytime you want at the profile page
              </div>
              <div
                className='w-[100%] hover:bg-[#019B7E] rounded-[6px] bg-[#01AB8E] p-2 text-center mt-4 text-white font-bold cursor-pointer'
                onClick={() => {
                  onSubmit()
                }}
              >
                Done
              </div>
              <div
                onClick={() => {
                  onOpen(false)
                }}
                className='text-[14px] text-gray-600 w-[100%] p-1 mt-2 rounded-[6px] hover:bg-gray-50 text-center cursor-pointer'
              >
                skip now
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default React.memo(OnBoarding)