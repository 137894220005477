import { PostDisplayType } from '../../services/post-service/types'
import React, { ReactElement, useEffect } from 'react'
import { CommentDisplayType } from '../../services/comment-service/types'
import ContentBottomBar from '../morecules/ContentBottomBar'
import ContentNameBar from '../morecules/ContentNameBar'
import { Link, useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import { CommentService } from '../../services/comment-service'
import { decryptData } from '../../utils/crypto';
import ReactPlayer from 'react-player';

type ContentProps = {
  content: Partial<PostDisplayType> & Partial<CommentDisplayType>
  isLine: boolean | undefined
  isMain?: boolean
  types: 'post' | 'comment'
  isShort?: boolean
}

const ContentDisplay = ({
  types,
  content,
  isLine,
  isMain = false,
  isShort = false,
}: ContentProps) => {
  const navigate = useNavigate()
  const { userId } = useUserStore()

 
  useEffect(() => {



    if (!content.isRead && types == 'comment') {
      const res = CommentService.UpdateComment('commentId', {
        commentId: content.commentId,
        isRead: true,
      })
    }
  }, [])

  if (!isMain) {

    //console.log("컨텐트 확인")
    //console.log(content)
    
    //console.log("contentcreatedbY:")
    //console.log(content.createdBy)
    let youtubeLink: string;
    let reactPlayerCode: React.ReactNode;

    youtubeLink = '';

    let decryptedContent: string;
    let titleElement: ReactElement;

    titleElement = <></>

    if(content.isEncrypted){

      console.log("이제 복호화를 해보자..!")
      console.log(content.content)
      try {
        decryptedContent = content.content ? decryptData(content.content) : '';
      } catch (error) {
        console.error('Error decrypting content:', error);
        decryptedContent = content.content ?? '';
      }
    } else {
      decryptedContent = content.content ?? '';
    }

    if(decryptedContent.includes("YouTube Link:")){ //보여줘야 할 유튜브 영상이 있다면
      let words = decryptedContent.split(" ");
      youtubeLink = words[words.length - 1];
      console.log("youtubeLink:")
      console.log(youtubeLink);

      reactPlayerCode = <><br /><br /><ReactPlayer url={youtubeLink} width='100%'/></>
    }

    if(content.title){
      titleElement = <div className='pb-2 pt-2 whitspace-pre-wrap font-semibold'>{content.title}</div> 
    }


    return (
      <div className='flex flex-row px-3 pt-1 text-[15px]'>
        <div className='flex flex-col justify-start items-center w-[12%] pt-1 max-w-[30px]'>
          <img
            onClick={() => {
              if (content.userId != userId)
                navigate(`/profile/${content.userId}`)
              else navigate(`/profile`)
            }}
            src={
              content.user?.profileImageUrl
                ? content.user?.profileImageUrl
                : '/svg/profile.svg'
            }
            className='w-full h-auto aspect-square rounded-full object-cover border border-gray-200 cursor-pointer'
          />
          {isLine && (
            <div className='border-[0.9px] border-slate-200 h-full mt-1'></div>
          )}
        </div>
        <div className='w-[90%] pl-2 pb-3'>
          <ContentNameBar
            contentType={types}
            name={content.user?.name}
            id={types == 'post' ? content.postId : content.commentId}
            createdAt={content.createdAt}
            contentCreatedBy={content.createdBy}
            contentEmotion={content.emotion}
          />
          <div
            className={`${isShort ? 'line-clamp-3 mb-2' : 'pb-2'} pt-0 whitespace-pre-wrap`}
          >
            {titleElement}
            {decryptedContent}
            {reactPlayerCode}
          </div>
          {content.imageUrl && (
            <div className='relative max-w-[100%] py-2'>
              <img
                src={content.imageUrl}
                className='max-h-[260px] rounded-[12px] border'
              />
            </div>
          )}
          <ContentBottomBar content={content} types={types} />
        </div>
      </div>
    )
  } else {


    let decryptedContent: string;
    let titleElement: ReactElement; 

    titleElement = <></>


    
    //onsole.log("여기 들어오긴 하나?")

    if(content.isEncrypted){

      console.log("이제 복호화를 해보자..!")
      console.log(content.content)
      try {
        decryptedContent = content.content ? decryptData(content.content) : '';
      } catch (error) {
        console.error('Error decrypting content:', error);
        decryptedContent = content.content ?? '';
      }
    } else {
      decryptedContent = content.content ?? '';
    }

    if(content.title){
      titleElement = <div className='pb-2 pt-2 whitspace-pre-wrap font-semibold'>{content.title}</div>
    }

   

  


    return (
      <div className='px-4 pt-0 text-[15px]'>
        <div className='flex flex-row items-center content-center'>
          <div className='flex flex-col justify-start items-center w-[12%] pt-1 max-w-[30px]'>
            <img
              src={
                content.user?.profileImageUrl
                  ? content.user?.profileImageUrl
                  : '/svg/profile.svg'
              }
              className='w-full h-auto aspect-square rounded-full object-cover border border-gray-400'
            />
            {isLine && (
              <div className='border-[0.9px] border-slate-200 h-full mt-1'></div>
            )}
          </div>
          <div className='w-[90%] pl-2'>
            <ContentNameBar
              contentType={types}
              name={content.user?.name}
              id={types == 'post' ? content.postId : content.commentId}
              createdAt={content.createdAt}
              contentCreatedBy={content.createdBy}
              contentEmotion={content.emotion}
            />
          </div>
        </div>
        {titleElement}
        <div className='pb-2 pt-2 whitespace-pre-wrap'>{decryptedContent}</div>
        {content.imageUrl && (
          <div className='relative max-w-[100%] py-2'>
            <img
              src={content.imageUrl}
              className='max-h-[260px] rounded-[12px] border'
            />
          </div>
        )}
        <ContentBottomBar content={content} types={types} />
      </div>
    )
  }
}

export default React.memo(ContentDisplay)
