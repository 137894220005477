import { MessageCircleQuestion } from 'lucide-react'
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import {OpenAI, AzureOpenAI} from 'openai'
import axios from "axios";
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../../services/supabase'
import getUserLocale from 'get-user-locale'
import { getStreak } from 'datetime-streak'
import { PostDisplayType, PostType } from '@/services/post-service/types'



type MainPostListProps = {
  posts: PostDisplayType[] | undefined
}

const MAX_RETRIES = 0;
const RETRY_DELAY = 15000; // 1 second

async function retryableAxiosPost(url: string, data: any, config: any, retries = 0): Promise<any> {
  try {
    return await axios.post(url, data, config);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
      console.log("429 에러 발생, 재시도 중...")
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryableAxiosPost(url, data, config, retries + 1);
    }
    throw error;
  }
}



type ExamplesProps = {
  onClick: (value: string) => void
}


const TodayFortune = () => {

  const EXAMPLES = [
    "An exciting opportunity lies ahead of you.",
    "Beware of all enterprises that require new clothes.",
    "Stay healthy. Walk a mile.",
    "The family that plays together stays together.",
    "Live this day as if it were your last.",
    "Dance as if no one is watching."
  ] 

  const navigate = useNavigate()
  const { userId, bio, name } = useUserStore()

  const [fortune, setFortune] = useState<string>('Baking Fortune Cookie...');
  //const [secondQuestion, setSecondQuestion] = useState<string>('Generating Question...');
  //const [thirdQuestion, setThirdQuestion] = useState<string>('Generating Question...');


  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  const [streak, setStreak] = useState(0);
  const [postLength, setPostlength] = useState(0);

  console.log("현재 유저의 아이디는:")
  console.log(userId)
  console.log("현재 유저 바이오: "+bio)
  console.log("현재 유저 이름: "+name)

  let handleQuestionClick = (value: string) => {
    console.log("this is question: ")
    console.log(value)
    navigate('/write', { state: { question: value } })
  }

  var titleElement = <></>



  useEffect(() => {

    const createFortune = async () => {
        
      //이 사람이 지금까지 쓴 포스트 다 불러오기 
      const { data: postHistory, error: postHistoryError } = await supabase
      .from('post')
      .select('content')
      .eq('user_id', userId);


      //이 사람 personal question 보기 
      const { data: fortuneHistory, error: fortuneHistoryError } = await supabase
      .from('user')
      .select('fortune, fortune_date')
      .eq('user_id', userId);

      if(!fortuneHistoryError){

        const date = new Date();

        //var dateChanged = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
        var dateChanged = date.getFullYear()+'-'+((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))+'-'+((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
        console.log(dateChanged)

        console.log(fortuneHistory[0].fortune_date)
        console.log(dateChanged)
        console.log(fortuneHistory[0].fortune_date != dateChanged)


        if(fortuneHistory[0].fortune == null || fortuneHistory[0].fortune_date != dateChanged){ 
          
          console.log("여기를 들어와 버렸다..")//질문 새로 생성해야할 때 

          if(!postHistoryError){

            if(postHistory.length>=4){
    
    
              //console.log("지금까지 유저가 쓴 포스트: ")
              //console.log(data)
    
              var postList = ''
              //const postList =  "'" + JSON.stringify(data) + "'"
              //console.log(postList)
    
              for(var i=0; i<postHistory.length; i++){
                //console.log(postHistory[i].content)
                postList += (i.toString() +': '+postHistory[i].content + ' //// ')
              }
    
              //console.log(postList)

              var SystemPrompt = `You are a chinese fortune teller. You will be given a list of journals that a user wrote within the past few days. Each post is separated by '////.' Carefully read the journals and 
              craft a fortune cookie appropriate for this user. It should be hopeful and mysterious. The fortune shouldn't be longer than 3 sentences. The fortune should be in the language that 
              the user wrote mostly in.
              `
              
              if(fortuneHistory[0].fortune != null) {
                SystemPrompt = SystemPrompt + "additionally, this is the fortune cookie user received before. The new fortune you generate should not be the same with this one: "+fortuneHistory[0].fortune
              }
    
              //openai 호출 
              try {
                const completion2 = await axios.post(
                  'https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-melonn01/chat/completions?api-version=2024-09-01-preview',
                  {
                    model: 'gpt-4o-mini',
                    messages: [
                      { role: 'system', content: SystemPrompt},
                      { role: 'user', content: postList },
                    ],
                    temperature: 0.8,
                  },
                  {
                    headers: {
                      'api-key': 'e9607dca07eb4b4ebdde6d2cd11a5163',
                    }
                  }
                );
                // If the request is successful, the response will contain data
                //console.log("현재 모델:");
                //console.log(model);
                console.log("Azure OpenAI generation!");
                console.log(completion2.data.choices[0].message.content); // Access `data.choices`
            
                setFortune(completion2.data.choices[0].message.content)

                //supabase 로 유저 데이터에 넣어주기 

                const tableName = 'user';
                const primaryKeyName = 'user_id';
                const body = {
                  user_id: userId, // The primary key value for the user row to be updated
                  fortune: completion2.data.choices[0].message.content,
                  fortune_date: dateChanged // Date format should match your database's requirements
                };

                await updateData(tableName, primaryKeyName, body);
            
              
              } catch (error) {
    
                //openai로 질문 생성 안되면 그냥 원래 랜덤 질문 넣어주기 
                console.error("Error occurred during OpenAI request:", error);
    
                const date = new Date().getDate();
                console.log("today's date: "+date)
                //const randomIndex = 
                const questionSentence = EXAMPLES[date%(EXAMPLES.length-1)]
                console.log(questionSentence)
                setFortune(questionSentence)
              }
    
            }
    
            else {
              const date = new Date().getDate();
              console.log("today's date: "+date)
              //const randomIndex = 
              const questionSentence = EXAMPLES[date%(EXAMPLES.length-1)]
              console.log(questionSentence)
              setFortune(questionSentence)
            }
            
          }
        }

        else if(fortuneHistory[0].fortune != null && fortuneHistory[0].fortune_date == dateChanged){

          //이미 생성된 오늘의 질문이 있다면 그냥 그대로 넣어준다. 새로 생성하려고 api 부를 필요 없이. 
          console.log("이미 알고 있는 게 있으니까!")
          setFortune(fortuneHistory[0].fortune)
        }
        
      }

      else {
        const date = new Date().getDate();
        console.log("today's date: "+date)
        //const randomIndex = 
        const questionSentence = EXAMPLES[date%(EXAMPLES.length-1)]
        console.log(questionSentence)
        setFortune(questionSentence) 
      }


      console.log(error)
      console.log(titleElement)

    };

    createFortune(); // Call the function to fetch data
  }, []); // The empty dependency array means this runs once when the component mounts


  
  


  



  return (
    

    <div className='w-[100%] flex justify-center items-center mt-3'>
    <div className='grid grid-flow-row gap-1 mt-4 p-4 rounded-[12px] bg-gray-100/50 w-[90%] sm:w-[100%]'>
    <p>Today's Fortune Cookie 🥠</p>
          <div
            
            className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
            onClick={() => {
              console.log("clicked fortune...")
            }}
            
          >
            
            {fortune}
          </div>

        
    </div>
  </div>
  )
}

export default React.memo(TodayFortune)
