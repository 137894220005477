import React, { Dispatch, SetStateAction, useState } from 'react'
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogOverlay,
  AlertDialogPortal,
  AlertDialogTitle,
  AlertDialogTrigger,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from '../atom/ui/alert-dialog'
import { useUserStore } from '../../store/useUserStore'
import TextareaAutosize from 'react-textarea-autosize'
import { supabase } from '../../services/supabase'
import Snackbar from './SnackBar'
import { SnackBarTypes } from './SnackBarUI'


type FeedbackModalProps = {
  children: React.ReactNode
  desc?: string
}

const ForYouInfoModal = ({
  children,
  desc = 'Thank you for helping us! We want to hear from you',
}: FeedbackModalProps) => {
  const { userId, name } = useUserStore()
  const [text, setText] = useState<string>()

  const submitFeedback = async () => {
    if (!text) return

    const body = {
      user_id: userId,
      name: name,
      content: text,
    }
    const { data, error } = await supabase.from('feedback').insert(body)

    if (!data) {
      setText('')
      Snackbar.show({
        text: SnackBarTypes.FEEDBACK,
      })
    }
  }

  return (
    <AlertDialog>
      <AlertDialogTrigger className='cursor-pointer hover:bg-slate-50 py-1 px-2 rounded-[4px]'>
        {children}
      </AlertDialogTrigger>
      <AlertDialogContent className='bg-white/100 w-[90%] rounded-[12px] sm:rounded-[12px] overflow-hidden pb-4 border-none'>
        <AlertDialogHeader>
          <AlertDialogTitle className='text-[20px] font-medium sm:text-center'>
            What's For You Page?
          </AlertDialogTitle>
          <AlertDialogDescription></AlertDialogDescription>
          <div className='text-[15px] sm:text-center'>
            <div>Get fresh, personalized insights once a day with the For You Page! <br /><br />Each day, uncover new reflections and perspectives based on your previous journal entries, updated daily to help you understand yourself better.</div>
            
          </div>
        </AlertDialogHeader>
        <AlertDialogFooter className='justify-end w-full flex flex-row items-center'>
          <AlertDialogCancel className='border-none p-2 px-8 ml-2 bg-black text-white rounded-[4px] hover:bg-gray-800 hover:text-white font-medium'>
            Close
          </AlertDialogCancel>
         
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  )
}

export default React.memo(ForYouInfoModal)
