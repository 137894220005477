import { MessageCircleQuestion } from 'lucide-react'
import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import { useUserStore } from '../../store/useUserStore'
import {OpenAI, AzureOpenAI} from 'openai'
import axios from "axios";
import {
  insertData,
  returnSupabase,
  supabase,
  updateData,
  upsertData,
} from '../../services/supabase'
import getUserLocale from 'get-user-locale'
import { getStreak } from 'datetime-streak'
import { PostDisplayType, PostType } from '@/services/post-service/types'



type MainPostListProps = {
  posts: PostDisplayType[] | undefined
}

const MAX_RETRIES = 0;
const RETRY_DELAY = 15000; // 1 second

async function retryableAxiosPost(url: string, data: any, config: any, retries = 0): Promise<any> {
  try {
    return await axios.post(url, data, config);
  } catch (error) {
    if (axios.isAxiosError(error) && error.response?.status === 429 && retries < MAX_RETRIES) {
      console.log("429 에러 발생, 재시도 중...")
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY));
      return retryableAxiosPost(url, data, config, retries + 1);
    }
    throw error;
  }
}



type ExamplesProps = {
  onClick: (value: string) => void
}


const QuestionSuggest = ({ posts }: MainPostListProps) => {


const EXAMPLES = [
  "What is my favorite movie?",
  "When is my favorite season?",
  "What is my favorite food?",
  "Do I like traveling?",
  "Do I believe in true love?",
  "What do I want to learn?",
  "What is the best advice I’ve received?",
  "What am I worried about?",
  "What have I achieved this year?",
  "What is missing in my life and how can I get that?",
  "What would I do if I knew I was going to die soon?",
  "Who is my mentor/rolemodel?",
  "What do I want to do differently?",
  "Have I made someone smile today?",
  "What have I given up on?",
  "How many of my friends would I trust with my life?",
  "How would my friends describe me?",
  "I couldn’t imagine living without . . .",
  "What do I love about life?",
  "What do I fear?",
  "What do I miss the most from my childhood?",
  "Am I confident about myself?",
  "What is my MBTI?",
  "My wishlist lately...",
  "My favorite youtube channel is...",
  "My favorite artist is...",
  "My favorite country to travel is...",
  "When I grow older I want to...",
  "What is the first thing I do after waking up in the morning?",
  "Where do I find my inner sanctuary, a place of peace and tranquility?",
  "What assumptions am I making that could be influencing my perceptions?",
  "What am I most proud of accomplishing recently?",
  "If I wasn't afraid, I would...",
  "The last book I would write is..."
] 

  const navigate = useNavigate()
  const { userId, bio, name } = useUserStore()

  const [question, setQuestion] = useState<string>('Generating question...');
  const [secondQuestion, setSecondQuestion] = useState<string>('Generating Question...');
  const [thirdQuestion, setThirdQuestion] = useState<string>('Generating Question...');


  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);


  const [streak, setStreak] = useState(0);
  const [postLength, setPostlength] = useState(0);

  console.log("현재 유저의 아이디는:")
  console.log(userId)
  console.log("현재 유저 바이오: "+bio)
  console.log("현재 유저 이름: "+name)

  let handleQuestionClick = (value: string) => {
    console.log("this is question: ")
    console.log(value)
    navigate('/write', { state: { question: value } })
  }

  var titleElement = <></>



  useEffect(() => {

    const createQuestion = async () => {



      //글쓴 날짜들 구하기 
      const {data: streakData, error: streakError} = await supabase 
      .from('post')
      .select('created_at')
      .eq('user_id', userId)
      .is('status', null);
 



      var streakCount = 0; 


      if(streakData!=null){

        
        
        //var tmpDay = 0; 
        //var today = new Date().getDate();
        //var lastWroteDay = streakData[streakData.length-1].created_at.split("T")[0].split("-")[2];

        //console.log("오늘 날짜: " + today)
        //console.log("마지막으로 쓴 날: " + lastWroteDay)

        var dates = [];


      
        
        for(var i=streakData.length-1; i>=0; i--){


          //console.log(data[i].created_at);
          dates.push(streakData[i].created_at);

          //var today = new Date().getDate();
          //console.log(today)
          
        }

        console.log("dates :")
        console.log(dates)

        var streak = getStreak(dates);
        console.log("streak: ")
        console.log(streak)

        setStreak(streak.currentDailyStreak)

        //setSecondQuestion(data[0].content)
        //setThirdQuestion(data[1].content)
      }


      var filterLocale; 
      filterLocale = getUserLocale();

      if(filterLocale == 'ko-KR'){
        filterLocale = 'kr'
      } else {
        filterLocale = 'en'
      }
      //한국 아니면 다 영어로 표기하기 

      console.log(filterLocale)

      const {data, error} = await supabase 
      .from('questions')
      .select('content')
      .eq('locale', filterLocale)

      if(data!=null){
        console.log(data)

        setSecondQuestion(data[0].content)
        setThirdQuestion(data[1].content)
      }

      //이 사람이 지금까지 쓴 포스트 다 불러오기 
      const { data: postHistory, error: postHistoryError } = await supabase
      .from('post')
      .select('content')
      .eq('user_id', userId);


      //이 사람 personal question 보기 
      const { data: questionHistory, error: questionHistoryError } = await supabase
      .from('user')
      .select('personal_question, personal_question_date')
      .eq('user_id', userId);

      if(!questionHistoryError){

        const date = new Date();

        //var dateChanged = ((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear()
        var dateChanged = date.getFullYear()+'-'+((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1)))+'-'+((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate()))
        console.log(dateChanged)

        console.log(questionHistory[0].personal_question_date)
        console.log(dateChanged)
        console.log(questionHistory[0].personal_question_date != dateChanged)


        if(questionHistory[0].personal_question == null || questionHistory[0].personal_question_date != dateChanged){ 
          
          console.log("여기를 들어와 버렸다..")//질문 새로 생성해야할 때 

          if(!postHistoryError){

            //console.log(data)

            if(postHistory.length>0){

              setPostlength(postHistory.length)

              titleElement = <>
              <p className='text-gray-700 mb-2 text-[17px] font-medium'>
                Random Questions
              </p>
              </>
            }
    
            if(postHistory.length>=4){
    
    
              //console.log("지금까지 유저가 쓴 포스트: ")
              //console.log(data)
    
              var postList = ''
              //const postList =  "'" + JSON.stringify(data) + "'"
              //console.log(postList)
    
              for(var i=0; i<postHistory.length; i++){
                //console.log(postHistory[i].content)
                postList += (i.toString() +': '+postHistory[i].content + ' //// ')
              }
    
              //console.log(postList)

              var SystemPrompt = `you will be given a list of journals that a user wrote within the past few days. Each post is separated by '////.' Carefully read the journals and think if you were this user, what kind of question would you have asked to yourself? 
              It could be something profound or conetamplative, or it could be something light and daily. It doesn't necessarily have to be something that's related to user's previous posts. 
              It can be something totally random. Think of user's personality, and throw a random question based on that personality. Try to bring in diverse topics as much as possible - art, politics, tech,
              philosophy, humor, movie, music, whatever. Make it as interesting as possible. Try to keep your question detailed and concrete. Don't be abstract. 
        
              Tell me that question. 
              Your output should only be that question, nothing else. Your question should not be longer than 15 words. Your question should be in the language that most of the user's journal was written in. 
              These are the examples you can use to reference while making your question. 

              Q1: If people like you, what would the reason be?
              Q2: If you could go back in time, when would you go to? 
              Q3: What's your biggest complex? 
              Q4: It seems that recently you are caring a lot about your mental health. How are you feeling today? 
              Q5: Are you excited for today's date? How do you feel about it? 
              Q5: Did you get a good sleep last night? 
              Q6: Do you like turning old? 
              Q7: Who is your favorite fictional character? 
              Q8: When was the happiest moment in your life? 
              Q9: When was the saddest moment in your life? 
              `
              
              if(questionHistory[0].personal_question != null) {
                SystemPrompt = SystemPrompt + "additionally, this is the previous question user received. The new question you generate should not be the same with this one: "+questionHistory[0].personal_question
              }
    
              //openai 호출 
              try {
                const completion2 = await axios.post(
                  'https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-melonn01/chat/completions?api-version=2024-09-01-preview',
                  {
                    model: 'gpt-4o-mini',
                    messages: [
                      { role: 'system', content: SystemPrompt},
                      { role: 'user', content: postList },
                    ],
                    temperature: 0.8,
                  },
                  {
                    headers: {
                      'api-key': 'e9607dca07eb4b4ebdde6d2cd11a5163',
                    }
                  }
                );
                // If the request is successful, the response will contain data
                //console.log("현재 모델:");
                //console.log(model);
                console.log("Azure OpenAI generation!");
                console.log(completion2.data.choices[0].message.content); // Access `data.choices`
            
                setQuestion(completion2.data.choices[0].message.content)

                //supabase 로 유저 데이터에 넣어주기 

                const tableName = 'user';
                const primaryKeyName = 'user_id';
                const body = {
                  user_id: userId, // The primary key value for the user row to be updated
                  personal_question: completion2.data.choices[0].message.content,
                  personal_question_date: dateChanged // Date format should match your database's requirements
                };

                await updateData(tableName, primaryKeyName, body);
            
              
              } catch (error) {
    
                //openai로 질문 생성 안되면 그냥 원래 랜덤 질문 넣어주기 
                console.error("Error occurred during OpenAI request:", error);
    
                const date = new Date().getDate();
                console.log("today's date: "+date)
                //const randomIndex = 
                const questionSentence = EXAMPLES[date%(EXAMPLES.length-1)]
                console.log(questionSentence)
                setQuestion(questionSentence)
              }
    
            }
    
            else {
              const date = new Date().getDate();
              console.log("today's date: "+date)
              //const randomIndex = 
              const questionSentence = EXAMPLES[date%(EXAMPLES.length-1)]
              console.log(questionSentence)
              setQuestion(questionSentence)
            }
            
          }
        }

        else if(questionHistory[0].personal_question != null && questionHistory[0].personal_question_date == dateChanged){

          //이미 생성된 오늘의 질문이 있다면 그냥 그대로 넣어준다. 새로 생성하려고 api 부를 필요 없이. 
          console.log("이미 알고 있는 게 있으니까!")
          setQuestion(questionHistory[0].personal_question)
        }
        
      }

      else {
        const date = new Date().getDate();
        console.log("today's date: "+date)
        //const randomIndex = 
        const questionSentence = EXAMPLES[date%(EXAMPLES.length-1)]
        console.log(questionSentence)
        setQuestion(questionSentence) 
      }


      

      
      
      console.log(error)
      console.log(titleElement)


      /*
      try {
        const completion2 = await retryableAxiosPost(
          'https://melonn-main.openai.azure.com/openai/deployments/gpt-4o-melonn01/chat/completions?api-version=2024-09-01-preview',
          {
            model: model,
            messages: [
              { role: 'system', content: SystemPrompt },
              ...conversations(),
              { role: 'user', content: inputPrompt },
            ],
            temperature: 0.8,
          },
          {
            headers: {
              'api-key': 'e9607dca07eb4b4ebdde6d2cd11a5163',
            }
          }
        );
        // If the request is successful, the response will contain data
        //console.log("현재 모델:");
        //console.log(model);
        console.log("Azure OpenAI generation!");
        console.log(completion2.data.choices[0].message.content); // Access `data.choices`
    
        return completion2.data.choices[0].message.content
    
      
      } catch (error) {
        console.error("Error occurred during OpenAI request:", error);
      }
      */

      /*
      try {
        // Simulate a data fetch
        const response = await fetch('https://jsonplaceholder.typicode.com/todos/1');
        const result = await response.json();
        setData(result); // Update the state with fetched data
      } catch (error) {
        setError(error); // Handle any error that occurs
      } finally {
        setIsLoading(false); // Stop the loading indicator
      }
      */
    };

    createQuestion(); // Call the function to fetch data
  }, []); // The empty dependency array means this runs once when the component mounts


  
  


  



  return (
    

    <div className='w-[100%] flex justify-center items-center mt-3'>
    <div className='grid grid-flow-row gap-1 mt-4 p-4 rounded-[12px] bg-gray-100/50 w-[90%] sm:w-[100%]'>
      
          <div
            
            className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
            onClick={() => {
              handleQuestionClick(question)
            }}
            
          >
            <div className='text-[10px] flex justify-center items-center box w-5 h-5 rounded-[4px] mr-2 bg-gray-100'>
              <MessageCircleQuestion className='w-[14px]'/>
            </div>{' '}
            {question}
          </div>

          <div
            
            className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
            onClick={() => {
              handleQuestionClick(secondQuestion)
            }}
            
          >
            <div className='text-[10px] flex justify-center items-center box w-5 h-5 rounded-[4px] mr-2 bg-gray-100'>
              <MessageCircleQuestion className='w-[14px]'/>
            </div>{' '}
            {secondQuestion}
          </div>

          <div
            
            className='flex flex-row items-center mt-2 cursor-pointer text-gray-500 hover:text-gray-700 italic'
            onClick={() => {
              handleQuestionClick(thirdQuestion)
            }}
            
          >
            <div className='text-[10px] flex justify-center items-center box w-5 h-5 rounded-[4px] mr-2 bg-gray-100'>
              <MessageCircleQuestion className='w-[14px]'/>
            </div>{' '}
            {thirdQuestion}
          </div>
    </div>
  </div>
  )
}

export default React.memo(QuestionSuggest)
