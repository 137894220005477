import { useUserStore } from '../../../store/useUserStore'
import React, { useCallback, useEffect, useState } from 'react'
import ContentDisplay from '../ContentDisplay'
import { Link, useNavigate } from 'react-router-dom'
import { useOnlyPosts, useOnlyPostsCalendar } from '../../../hooks/posts'
import { useInView } from 'react-intersection-observer'
import PostSkeletons from '../../morecules/PostSkeletons'

const CalendarPosts = (startTime: any, endTime: any) => {
  const { userId } = useUserStore()
  const navigate = useNavigate()
  const {
    hasNextPage,
    posts,
    isLoading,
    isError,
    fetchNextPage,
    isFetchingNextPage,
  } = useOnlyPostsCalendar('userId', userId, startTime['startTime'], startTime['endTime'])

  const { ref, inView } = useInView()


  //const parsedDate = currentDate instanceof Date ? currentDate : new Date(currentDate);


  //const tmpDate = currentDate.toDate()
  //const startTime = new Date(currentDate.setHours(0,0,0,0))
  //const endTime= new Date(currentDate.setHours(23, 59, 59, 999))

  console.log("시작시간-1:")
  console.log(startTime['startTime'])
  console.log("종료시간-2:")
  console.log(startTime['endTime'])
  

  

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage()
    }
  }, [inView])

  return (
    <div>
      {!isLoading &&
        posts &&
        posts.map((post) => {
          return (
            <div
              onClick={() => navigate(`/post/${post.postId}`)}
              key={post.postId}
              className='py-2 border-b border-gray-200 hover:bg-gray-50 cursor-pointer'
            >
              <ContentDisplay types='post' content={post} isLine={false} />
            </div>
          )
        })}
      <>
        {!isLoading && posts.length == 0 && (
          <div className='sub'>No posts yet</div>
        )}
        {isLoading || isFetchingNextPage ? (
          <>
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
            <PostSkeletons />
          </>
        ) : (
          <>
            <br />
            {hasNextPage ? (
              <div className='h-10' ref={ref} />
            ) : (
              <div className='sub'></div>
            )}
          </>
        )}
      </>
    </div>
  )
}

export default React.memo(CalendarPosts)
