import { Check } from 'lucide-react';
import React, { useState } from 'react';

type ExamplesProps = {
  onClick: (value: string) => void;
};

const Emotions = ({ onClick }: ExamplesProps) => {
  const [selectedMood, setSelectedMood] = useState<string | null>(null);

  const moods = [
    { label: 'Happy', color: 'bg-cyan-400' },
    { label: 'Bored', color: 'bg-orange-900' },
    { label: 'Confused', color: 'bg-fuchsia-700' },
    { label: 'Angry', color: 'bg-rose-400' },
    { label: 'Lonely', color: 'bg-emerald-500' },
    { label: 'Sad', color: 'bg-indigo-600' },
    { label: 'Tired', color: 'bg-green-400' },
    { label: 'Passionate', color: 'bg-orange-400' },
    { label: 'Anxious', color: 'bg-amber-300' },
  ];

  const handleClick = (mood: string) => {
    if (selectedMood === mood) {
      setSelectedMood(null);
      onClick('null'); // Call the onClick prop with null
    } else {
      setSelectedMood(mood);
      onClick(mood);
    }
  };

  return (
    <div className='w-[100%] flex justify-center items-center mt-12'>
      <div className='grid grid-flow-row gap-1 mt-4 p-4 rounded-[12px] bg-gray-100/50 w-[90%] sm:w-[96%]'>
        <p className='text-gray-700 mb-2 text-[17px] font-medium'>
          I'm feeling...
        </p>
        <div className='grid grid-cols-3 grid-rows-3 gap-1'>
          {moods.map((mood) => (
            <div
              key={mood.label}
              className={`grid grid-flow-row gap-1 mt-4 p-4 rounded-[12px] cursor-pointer 
                ${selectedMood === mood.label ? `${mood.color} text-slate-50` : 'bg-gray-100'} 
                w-[90%] sm:w-[96%] text-center 
                ${selectedMood !== mood.label ? `` : ''}`}
              onClick={() => handleClick(mood.label)}
              style={{
                transition: 'background-color 0.3s',
              }}
            >
              {mood.label}
              {selectedMood === mood.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default React.memo(Emotions);
