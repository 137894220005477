// import AppLayout from "@/components/templates/AppLayout";
// import SnapshotListPage from "@/pages/snapshots/SnapshotListPage";
import { Route, Routes, useLocation } from 'react-router-dom'
// import AuthLayout from "./components/templates/AuthLayout";
// import SessionLayout from "./components/templates/SessionLayout";
import SignInPage from './pages/SignInPage'
import HomePage from './pages/HomePage'
import ProfilePage from './pages/ProfilePage'
import WritePage from './pages/WritePage'
import ForYouPage from './pages/ForYouPage'
import OuterLayout from './components/templates/OuterLayout'
import InnerLayout from './components/templates/InnerLayout'
import DetailPage from './pages/DetailPage'
import SessionLayout from './components/templates/SessionLayout'
import ActPage from './pages/ActPage'
import FindPage from './pages/FindPage'
import PersonaProfilePage from './pages/PersonaProfilePage'
import SettingPage from './pages/SettingPage'
import RouteChangeTracker from './components/templates/RouteChangeTracker'
import PolicyPage from './pages/PolicyPage'
import TermsPage from './pages/TermsPage'
import { isMobile } from 'react-device-detect'
import AdminPage from './pages/AdminPage'
import UsagePage from './pages/UsagePage'
import FaqPage from './pages/FaqPage'
import { useEffect } from 'react'

export default function Router() {
  RouteChangeTracker()
  const { pathname } = useLocation()

  useEffect(() => {
    // window.scrollTo(0, 0)
  }, [pathname])

  return (
    <Routes>
      <Route element={<SessionLayout />}>
        <Route element={<OuterLayout />}>
          <Route path='/' element={<HomePage />} />
        </Route>
        <Route element={isMobile ? <InnerLayout /> : <OuterLayout />}>
          <Route path='/write' element={<WritePage />} />
          <Route path='/write/:parentId' element={<WritePage />} />
          <Route path='/setting' element={<SettingPage />} />
          <Route path='/faq' element={<FaqPage />} />
        </Route>
        <Route element={<OuterLayout />}>
          <Route path='/for-you' element={<ForYouPage />} />
          <Route path='/post/:id' element={<DetailPage />} />
          <Route path='/act' element={<ActPage />} />
          <Route path='/profile' element={<ProfilePage />} />
          <Route path='/profile/:personaId' element={<PersonaProfilePage />} />
        </Route>
      </Route>
      <Route element={<InnerLayout />}>
        <Route path='/signin' element={<SignInPage />} />
        <Route path='/policy' element={<PolicyPage />} />
        <Route path='/terms' element={<TermsPage />} />
        <Route path='/admin-test' element={<AdminPage />} />
        <Route path='/admin-usage' element={<UsagePage />} />
        <Route path='/for-you' element={<ForYouPage />} />
      </Route>
    </Routes>
  )
}
