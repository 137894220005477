import { create } from 'zustand'

export type UserState = {
  userId: string
  email: string
  name: string
  imgUrl: string
  bio: string
  privacyMode: boolean
  singleMode: boolean
  setUserId: (userId: string) => void
  setEmail: (email: string) => void
  setName: (name: string) => void
  setImgUrl: (imgUrl: string) => void
  setBio: (bio: string) => void
  setPrivacyMode: (privacyMode: boolean) => void
  setSingleMode: (singleMode: boolean) => void
}

export const useUserStore = create<UserState>((set) => ({
  userId: '',
  email: '',
  name: '',
  imgUrl: '',
  bio: '',
  privacyMode: false,
  singleMode: false, 
  setPrivacyMode: (privacyMode: boolean) => {
    set((state) => ({ ...state, privacyMode: privacyMode }))
  },
  setSingleMode: (singleMode: boolean) => {
    set((state) => ({ ...state, singleMode: singleMode }))
  },
  setUserId: (by) => {
    set((state) => ({ ...state, userId: by }))
  },
  setEmail: (by) => {
    set((state) => ({ ...state, email: by }))
  },
  setName: (by) => {
    set((state) => ({ ...state, name: by }))
  },
  setImgUrl: (by) => {
    set((state) => ({ ...state, imgUrl: by }))
  },
  setBio: (by) => {
    set((state) => ({ ...state, bio: by }))
  },
}))
